import { addFrontend, FloatingVue } from '@lula-technologies-inc/lula-components';
import { createApp } from 'vue';
import { LDPlugin } from 'launchdarkly-vue-client-sdk';
import Development from '@/Development.vue';
import '@/assets/tailwind.css';
import '@lula-technologies-inc/lula-components/src/assets/index.css';
import '@/assets/index.css';

// Claim Form
import ClaimForm from '@/ClaimForm/ClaimForm.vue';
import claimFormStore from '@/ClaimForm/store';

// Claims Grid
import ClaimsGrid from '@/ClaimsGrid/ClaimsGrid.vue';
import claimsGridStore from '@/ClaimsGrid/store';

// Claims Grid
import AdminClaimsGrid from '@/AdminClaimsGrid/AdminClaimsGrid.vue';
import adminClaimsGridStore from '@/AdminClaimsGrid/store';

function provideAppParams(app, params) {
  Object.keys(params || {}).forEach((key) => {
    app.provide(key, params[key]);
  });
  return app;
}

addFrontend('claims-form', (selector, params) => {
  const app = provideAppParams(createApp(ClaimForm), params);
  if (params.launchDarklyApiKey) {
    app.use(LDPlugin, { clientSideID: params.launchDarklyApiKey });
  }
  app.use(claimFormStore);
  app.use(FloatingVue);
  app.mount(selector);
});

addFrontend('claims-grid', (selector, params) => {
  const app = provideAppParams(createApp(ClaimsGrid), params);
  app.use(claimsGridStore);
  app.use(FloatingVue);
  app.mount(selector);
});

addFrontend('claims-grid-admin', (selector, params) => {
  const app = provideAppParams(createApp(AdminClaimsGrid), params);
  if (params.launchDarklyApiKey) {
    app.use(LDPlugin, { clientSideID: params.launchDarklyApiKey });
  }
  app.use(adminClaimsGridStore);
  app.use(FloatingVue);
  app.mount(selector);
});

if (process.env.VUE_APP_DEVELOPMENT === 'True') {
  const app = createApp(Development);
  app.mount('#app');
}
