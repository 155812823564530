<template>
  <div>
    <FileUpload
      :disabled="disabled"
      :multiple="true"
      @upload="attachFile"
      accept="image/png, image/jpeg, image/jpg, application/pdf" />
    <div class="grid grid-cols-1 md:grid-cols-3">
      <TransitionGroup name="file-list">
        <div v-for="file in fileList" :key="file.id" class="m-4">
          <div class="relative">
            <div
              class="
                transition-all z-10 duration-100 absolute opacity-0
                w-full h-full cursor-pointer hover:opacity-100"
              @click="file.object ? downloadFile(file.url, file.name) : removeFile(file)"
              style="background: rgba(0, 0, 0, .25)">
              <div class="flex w-full justify-between p-4">
                <span />
                <span class="text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                    v-if="file.object">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="
                          M3 16.5v2.25A2.25 2.25 0
                          005.25 21h13.5A2.25 2.25
                          0 0021 18.75V16.5M16.5 12L12
                          16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    v-else>
                      <path
                        fill-rule="evenodd"
                        d="
                          M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000
                          2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0
                          100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7
                          8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0
                          00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clip-rule="evenodd" />
                    </svg>
                </span>
              </div>
            </div>
            <img
              :src="file.url || file.encode"
              class="w-full h-60 object-cover md:w-80"
              ignore-src
              v-if="isImage(file.name)" />
              <div
                class="
                  flex items-center justify-center w-full
                  bg-gray-50 h-60 m-auto cursor-pointer"
                  v-else>
                <div class="relative bg-white border rounded-md h-3/4 w-2/3">
                  <div class="absolute bg-lula h-1 rounded-full w-full" />
                  <span class="absolute top-3 left-5 font-bold text-gray-400 text-sm">
                    PDF
                  </span>
                  <div class="absolute top-10 left-5 bg-gray-100 rounded-full w-1/2 h-2" />
                  <div class="absolute top-14 left-5 bg-gray-100 rounded-full w-1/4 h-2" />
                  <div class="absolute flex flex-col justify-center items-center top-20 w-full">
                    <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
                    <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
                    <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
                    <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
                  </div>
                </div>
            </div>
          </div>
          <input
            type="text"
            :disabled="file.object"
            class="text-center w-full bg-white border-none hover:bg-white"
            placeholder="Enter image description"
            v-model="file.description"
            v-if="isImage(file.name)" />
          <input
            type="text"
            :disabled="file.object"
            class="text-center w-full bg-white border-none hover:bg-white"
            placeholder="Enter file description"
            v-model="file.description"
            v-else />
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import FileUpload from './FileUpload.vue';
import axios from 'axios';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  })
}

export default {
  props: [ 'disabled' ],
  components: { FileUpload },
  setup(props) {

    const store = useStore();
    const fileList = ref([]);

    async function loadAttachments() {
      const attachments = await Promise.all((store.state.attachments || [])
        .map(async (attachment) => {
          try {
            const file = await axios({
              url: attachment.url,
              method: 'GET',
              responseType: 'blob',
            });
            const encode = await getBase64(file.data);
            return {
              ...attachment,
              file: file.data,
              encode,
            }
          } catch(err) {
            console.log(err);
            return null;
          }
        }))
      fileList.value = attachments
        .filter(att => att)
        .filter(att => !att.type)
    }

    onMounted(loadAttachments);

    watch(() => fileList.value, () => {
      store.commit('setItem', {
        key: 'attachments',
        value: fileList.value
      });
    }, { deep: true });

    return {
      fileList,
      loadAttachments,
      isSubmitting: computed(() => props.disabled),
      async attachFile(file) {
        const encode = await getBase64(file);
        fileList.value.push({
          id: Date.now().toString(32),
          file,
          encode,
          name: file.name,
          description: file.name,
          type: null,
        });
      },
      removeFile(file) {
        if (confirm('Would you like to remove this file?')) {
          fileList.value = fileList.value.filter(fl => {
            return fl !== file;
          });
        }
      },
      async downloadFile(url, name) {
        const download = await axios({ url, responseType: 'blob' });
        const downloadUrl = URL.createObjectURL(download.data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      },
      isImage(fileName) {
        if (!fileName) {
          return false;
        }
        if (typeof fileName !== typeof '') {
          return false;
        }
        const reg = new RegExp('jpeg|png|jpg', 'g');
        return reg.test(fileName.toLowerCase());
      }
    }
  }
}
</script>

<style scoped>
.file-list-enter-active,
.file-list-leave-active {
  transition: all 0.5s ease;
}
.file-list-enter-from,
.file-list-leave-to {
  opacity: 0;
}
</style>
