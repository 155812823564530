<template>
  <div class="max-w-4xl">
    <div class="my-4 rounded-lg bg-green-50 p-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="hidden h-16 w-16 text-lula-success md:block"
        viewBox="0 0 20 20"
        fill="currentColor">
        <path
          fill-rule="evenodd"
          d="
            M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1
            0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414
            1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd" />
      </svg>
      <div class="my-4">
        <h3 class="text-2xl font-bold text-gray-700">
          Claim successfully filed.
        </h3>
        <p>Don't worry we're on it!</p>
        <p class="text-lg text-gray-600 mt-4" v-if="account?.contactEmail">
          Meanwhile we've sent a confirmation message to {{ maskEmail(account.contactEmail) }}
        </p>
        <p class="mt-4 text-lg" v-if="claimUrl">
          <a class="font-bold text-blue-600 hover:underline" :href="claimUrl">
            Click here to see details
          </a>
        </p>
      </div>
    </div>
    <div class="bg-white rounded-md p-6 my-4" v-if="nextSteps.length > 0">
      <h3 class="text-2xl text-gray-800 font-bold mb-4">
        Next Steps
      </h3>
      <ol>
        <li class="list-disc mx-6 md:list-none md:mx-0" v-for="st in nextSteps" :key="st">
          <div class="flex items-center text-lg text-gray-600 my-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden h-12 w-12 mr-4 text-lula md:block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
            <p class="w-5/6">
              {{ st }}
            </p>
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { useStore } from 'vuex';

function maskEmail(email) {
  var maskedEmail = email.replace(/([^@.])/g, "*").split('');
  var previous  = "";
  for(let i=0;i<maskedEmail.length;i++){
    if (i<=1 || previous == "." || previous == "@"){
      maskedEmail[i] = email[i];
    }
    previous = email[i];
  }
  return maskedEmail.join('');
}

export default {
  setup() {

    const store = useStore();

    const account = inject('account') || null;
    const nextSteps = inject('nextSteps') || '';
    const claimUrl = inject('claimUrl') || '';

    return {
      maskEmail,
      account,
      nextSteps: nextSteps.split('|')
        .filter(st => st),
      claimUrl: claimUrl
        .replace('{{claimId}}', store.state.claim.id)
    }
  }
}
</script>
