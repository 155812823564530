<template>
  <div class="m-1 bg-white border rounded-md p-4" v-if="gridClaims">   
    <div class="hidden w-full flex text-lg text-gray-500 mb-4 md:block">
      <span 
        :class="{
          'text-lula': currentTabName === 'firstparty',
          'hover:text-gray-700': currentTabName !== 'firstparty'
        }"
        class="mr-4 cursor-pointer pb-1"
        @click="toggleTab('firstparty')">
          First Party Claims
      </span>
      <span v-show="showThirdPartyClaimsFeatureFlagVal"
        :class="{
          'text-lula': currentTabName === 'thirdparty',
          'hover:text-gray-700': currentTabName !== 'thirdparty'
        }"
        class="mr-4 cursor-pointer pb-1"
        @click="toggleTab('thirdparty')">
          Third Party Claims
      </span>
    </div>
    <div :class="{ 'hidden': claimsLoaded }" class="bg-white border rounded-md m-1 p-2">
      <div class="bg-gray-100 h-64 animate-pulse" />
    </div>
    <table :class="{ 'hidden': !claimsLoaded }" class="w-full">
      <tr class="border-b text-left text-gray-600">
        <th class="pb-2 font-bold w-full md:w-60">
          <span class="hidden md:inline">
            Claim ID
          </span>
          <span class="md:hidden">
            Claim
          </span>
        </th>
        <template v-for="col in columns" :key="col.label">
        <th class="hidden pb-2 font-light md:table-cell" v-if="col.label != 'Eligible Policies'">
          {{ col.label }}
        </th>
        <th class="hidden pb-2 font-light w-50 lg:table-cell" v-else>
          {{ col.label }}
        </th>
      </template>
      </tr>
      <tr>
        <td colspan="100">
          <input
            class="
              w-full border-none rounded-none -mt-1
              py-1 px-2 bg-gray-50 text-gray-600 text-lg"
            placeholder="Search claim by id"
            type="text"
            @input="searchClaims"
            v-model="claimSearch" />
        </td>
      </tr>
      <tr
        class="
          cursor-pointer text-gray-600
          hover:bg-gray-100"
        v-for="claim in gridClaims"
        :key="claim.id"
        @click="(evt) => selectClaim ? selectClaim(evt, claim) : null"
        v-if="gridClaims.length > 0">
          <td class="py-4 text-gray-500 pl-2">
            <div class="w-full flex justify-between md:hidden">
              <div class="flex flex-col">
                <span class="font-bold" v-if="claim.tpaClaimId">
                  {{ claim.tpaClaimId }}
                </span>
                <span
                  class="
                    w-36 bg-lula-alt px-2 text-white
                    rounded-full text-center mb-1"
                  v-else>
                    Claim ID Pending
                </span>
                <span v-if="claim.vehicle">
                  {{ claim.vehicle.key || claim.vehicle.vin || '' }}
                </span>
                <span v-if="claim.company">
                  {{ claim.company.businessLegalName || ''}}
                </span>
              </div>
              <div>
                <span>
                  {{ claim.created }}
                </span>
              </div>
            </div>
            <div class="hidden md:flex items-center">
                <span class="font-bold" v-if="claim.tpaClaimId">
                  {{ claim.tpaClaimId }}
                </span>
                <span
                  class="
                    w-48 bg-lula-alt py-1 px-4 text-white
                    rounded-full text-center font-bold flex items-center"

                  v-else>
                    <span class="w-full">Claim ID Pending</span>
                </span>
                <span
                  class="hidden mx-2 text-gray-700 md:block"
                  v-tooltip="`
                    We are waiting for our third party claims administrator
                    to share claim ID in one business day.`"
                  v-if="!claim.tpaClaimId">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-6 h-6">
                      <path
                        fill-rule="evenodd"
                        d="
                          M2.25 12c0-5.385 4.365-9.75
                          9.75-9.75s9.75 4.365 9.75
                          9.75-4.365 9.75-9.75 9.75S2.25 17.385
                          2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255
                          0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272
                          5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75
                          0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75
                          0 01-1.5 0v-.75c0-1.279 1.06-2.107
                          1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857
                          0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd" />
                    </svg>
                </span>
            </div>
          </td>
          <td
            :class="col.class || ''"
            class="py-4 text-gray-500"
            v-for="col in columns"
            :key="col.label"
            v-if="gridClaims.length > 0">
              <div class="hidden lg:inline" v-if="col.label === 'Eligible Policies'">
                 <span class="inline-block w-full text-sm" v-for="pol in col.format(claim)" :key="pol">
                  {{ pol }}
                 </span>               
              </div>
              <div class="hidden md:inline" v-else-if="col.label === 'Company / Vehicle' || col.label === 'Submitted / Date of Loss'">
                 <span class="inline-block w-full text-sm" v-for="val in col.format(claim)" :key="val">
                  {{ val }}
                 </span>               
              </div>
              <div class="hidden md:inline" v-else>
                <span>
                  {{ col.format(claim) || '' }}
                </span>
              </div>
          </td>
      </tr>
      <tr
        class="cursor-pointer hover:bg-gray-100"
        @click="$store.dispatch('viewMore')"
        v-if="!this.claimSearch && !this.isLoading">
          <td class="p-2 text-gray-600 text-sm md:text-base">
            View More
          </td>
          <td />
          <td />
          <td />
          <td />
          <td />
      </tr>
      <tr>
        <td colspan="100">
          <div class="text-center p-4" v-if="gridClaims.length === 0">
            <span class="text-gray-600">
              No Claims
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from 'moment';
import { useLDFlag, useLDReady } from 'launchdarkly-vue-client-sdk';

function mapClaimStatus(claimStatus) {
  switch(claimStatus) {
    case 'reported': {
      return 'Reported';
    }
    case 'open': {
      return 'Open';
    }
    case 'closed': {
      return 'Closed';
    }
    case 'duplicate-closed': {
      return 'Duplicate Closed';
    }
    default: {
      return claimStatus;
    }
  }
}

export default {
  inject: [
    'environment',
    'userId',
    'userEmail',
    'graphUrl',
    'graphHeaders',
    'companies',
    'vehicles',
    'selectClaim',
    'onMounted',
    'vehicleId',
    'tenantId',
    'mode',
    'demo'
  ],
  setup() {
    const isLdReady = useLDReady();
    const showThirdPartyClaims = 'Claims.ShowThirdPartyClaims';
    const showThirdPartyClaimsFeatureFlagVal = useLDFlag(showThirdPartyClaims, false);
    return { isLdReady, showThirdPartyClaimsFeatureFlagVal };
  },
  mounted() {
    const claimsResult = this.$store.dispatch('initialize', {
      environment: this.environment,
      userId: this.userId,
      userEmail: this.userEmail,
      graphUrl: this.graphUrl,
      graphHeaders: this.graphHeaders,
      tenantId: this.tenantId,
      mode: this.mode,
      demo: this.demo || false,
    });
    claimsResult.then(claims => {
      if (this.onMounted) {
        this.onMounted(claims);
      }
    });
    this.claimsLoaded =  true;
  },
  data() {
    return {
      claimSearch: '',
      isLoading: false,
      currentTabName: 'firstparty',
      claimsLoaded: false
    }
  },
  methods: {
    async getClaimsByTabName(tabName)
    {
      if(tabName === 'firstparty')
        {
            await this.$store.dispatch(
            'getClaims');
        }
        else
        {
          await this.$store.dispatch(
          'getThirdPartyClaims');
        } 
    },
    async searchClaims() {
      this.isLoading = true;
      if (this.claimSearch) {
        await this.$store.dispatch(
          'getClaimByTpaId',
          this.claimSearch
        );
      } else {

        await this.getClaimsByTabName(this.$store.tabName);
      }
      this.isLoading = false;
    },
    async toggleTab(tabName){
      if(this.currentTabName != tabName)
      {
        this.claimsLoaded = false;
        await this.getClaimsByTabName(tabName);
        this.currentTabName = tabName
        await this.$store.dispatch('setTabName', this.currentTabName)
        this.claimsLoaded = true;
      }
    }   
  },
  computed: {
    isAdmin() {
      return (this.mode || '').includes('admin');
    },
    columns() {
      if(this.isAdmin && !this.tenantId) {
        return [
          {
            label: 'Company / Vehicle',
            class: 'w-72',
            format: claim =>
              [ claim.businessLegalName, claim.insuredVehicleVin ]
          },
          {
            label: 'Eligible Policies',
            class: 'hidden lg:table-cell',
            format: claim =>
               claim.eligiblePolicies.split(';')
          },
          {
            label: 'Submitted / Date of Loss',
            class: 'px-4',
            format: claim =>
              [ claim.created, claim.incidentDate ]
          },
          {
            label: 'Status',
            class: 'text-lula font-bold pr-4',
            format: claim =>
              mapClaimStatus(claim.status)
          },
          {
            label: 'Is Total Loss',
            class: 'px-4',
            format: claim => {
              if(claim.isTotalLoss === true)
               return 'Yes'
              else if(claim.isTotalLoss === false)
               return 'No'
              else 
                return 'Unknown'
            }
              
          }
        ]
      } else {
        return [
          {
            label: 'Vehicle',
            format: claim => {
              return claim.vehicle?.key || claim.vehicle?.vin || '';
            }
          },
          {
            label: 'Submitted',
            format: claim =>
              moment.utc(claim.created).local().format('MM/DD/yyyy')
          },
          {
            label: 'Status',
            class: 'text-lula font-bold',
            format: claim =>
              mapClaimStatus(claim.status)
          },
        ]
      }
    },
    claims() {
      const claims = this.$store.state.claims;
      if (Array.isArray(claims)) {
        return claims
          .map(clm => {
            const createdDate =
              moment.utc(clm.created).local();
            const incidentDate =
              moment(clm.incidentDate);
            return {
              ...clm,
              createdDate,
              created: createdDate.format('MM/DD/yyyy'),
              incidentDate: incidentDate.format('MM/DD/yyyy')
            }
          })
      }
      return null;
    },
    gridClaims() {
      if (this.claims) {
        return this.claims
          .sort((a, b) =>
            a.createdDate.toDate() < b.createdDate.toDate() ? 1 : -1
          )
      }
      return null;
    }
  },
}
</script>
