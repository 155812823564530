/**
 * A subject
 * @typedef {{ 
 *  accessScope: '*' | string[],
 *  actorType: 'user' | 'service',
 *  emailAddress: string,
 *  id: string,
 *  system: 
 *  | {
 *      type: 'root'
 *    }
*  | {
 *      type: 'tenant',
 *      tenantId: string
 *    }
*  | {
 *      type: 'customer'
 *      tenantId: string,
 *      customerId: string
 *    }
 * }} Subject
 */

/**
 * 
 * @param {Record<string, string | number>} payload 
 * @returns {string}
 */
const base64Encode = (payload) => {
  return Buffer.from(JSON.stringify(payload)).toString('base64').replaceAll('=', '');
};

/**
 * 
 * @param {Record<string, string | number>} payload 
 * @returns {string}
 */
const createUnsignedJwt = (payload) => {
  const header= {
    alg: 'none',
  };
  return `${base64Encode(header)}.${base64Encode(payload)}.`;
};

/**
 * 
 * @param {Subject} subject 
 * @returns 
 */
const tokenFromSubject = (subject) => {
    const actorType = subject.actorType;
    const email = subject.emailAddress;
    const scope = subject.accessScope === '*' ? '*' : [...subject.accessScope].join(',');
    const baseClaims = {
        actor_type: actorType,
        email,
        sub: subject.id ?? email,
        scope,
    };
    if (subject.system.systemType === 'root') {
        return createUnsignedJwt({
        ...baseClaims,
        system_type: 'root',
        });
    } else if (subject.system.systemType === 'tenant') {
        return createUnsignedJwt({
        ...baseClaims,
        system_type: 'tenant',
        tenant_id: subject.system.tenantId,
        });
    } else if (subject.system.systemType === 'customer') {
        return createUnsignedJwt({
        ...baseClaims,
        system_type: 'customer',
        tenant_id: subject.system.tenantId,
        customer_id: subject.system.customerId,
        });
    } else {
        throw new Error('unsupported system type');
    }
}

/**
 * @returns {string}
 */
const customerDevelopmentToken = () => {
    const email = process.env.VUE_APP_DEVELOPMENT_CUSTOMER_EMAIL;
    const customerId = process.env.VUE_APP_DEVELOPMENT_CUSTOMER_ID;
    const tenantId = process.env.VUE_APP_DEVELOPMENT_TENANT_ID;
    return tokenFromSubject({
        id: email,
        emailAddress: email,
        accessScope: '*',
        actorType: 'user',
        system: {
            systemType: 'customer',
            tenantId,
            customerId,
        }
    });
}

/**
 * @returns {string}
 */
const tenantDevelopmentToken = () => {
    const email = process.env.VUE_APP_DEVELOPMENT_TENANT_EMAIL;
    const tenantId = process.env.VUE_APP_DEVELOPMENT_TENANT_ID;
    return tokenFromSubject({
        id: email,
        emailAddress: email,
        accessScope: '*',
        actorType: 'user',
        system: {
            systemType: 'tenant',
            tenantId,
        }
    });
}

module.exports = {
    customerDevelopmentToken,
    tenantDevelopmentToken,
}