<template>
  <div v-if="isLdReady && isAdmin">
    <!-- hidden for everybody but paddocks admins -->
    <Steps />
    <Transition class="mt-4" v-if="'claim' in $store.state">
      <IncidentForm v-if="currentStep === 'incident' && !this.$store.state.isThirdPartyClaim" />
      <ThirdPartyClaimIncidentForm v-else-if="currentStep === 'incident' && this.$store.state.isThirdPartyClaim" />
      <Admin v-else-if="currentStep === 'admin'" />
      <Confirm v-else-if="currentStep === 'confirmation'" />
    </Transition>
  </div>

  <!-- normal customers go to NARS -->
  <div v-if="!isAdmin" class="m-1 bg-white border rounded-md p-4">
    <div class="w-full flex justify-between items-center mt-8 pb-2">
      <h1 class="font-bold text-4xl">NEW: File claim directly with NARS</h1>
    </div>
    <div class="pb-2">
      <span class="pb-1">In order to serve you better and make sure claims are processed sooner, we are asking our customers to file directly with NARS. </span>
    </div>
    <div class="pb-2">
      <span class=""> You can file a claim by going to this website: <a href="https://www.narisk.com/report-a-claim" target="_new" class="font-bold text-lula cursor-pointer hover:underline">https://www.narisk.com/report-a-claim</a>.</span>
    </div>
    <div class="w-full flex justify-between items-center mt-8 pb-2"><div>&nbsp;</div>
      <button type="submit" onclick="location.href='https://www.narisk.com/report-a-claim';" class="transition duration-200 bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt"> Submit Claim </button>
    </div>
  </div>
</template>

<script>
import Steps from '@/ClaimForm/Steps.vue';
import IncidentForm from '@/ClaimForm/IncidentForm.vue';
import ThirdPartyClaimIncidentForm from '@/ClaimForm/ThirdPartyClaimIncidentForm.vue';
import ThirdPartyForm from '@/ClaimForm/ThirdPartyForm.vue';
import Admin from '@/ClaimForm/Admin.vue';
import Confirm from '@/ClaimForm/Confirm.vue';
import { useLDReady } from 'launchdarkly-vue-client-sdk'

export default {
  inject: [
    'userId',
    'userEmail',
    'graphUrl',
    'graphHeaders',
    'tenantId',
    'claimId',
    'environment',
    'onMounted',
    'mode',
  ],
  components: {
    Steps,
    IncidentForm,
    ThirdPartyForm,
    Admin,
    Confirm,
    ThirdPartyClaimIncidentForm
},
  setup() {

    const isLdReady = useLDReady();    
    return { isLdReady }

  },
    async mounted() {
      const claim = await this.$store.dispatch('initialize', {
      environment: this.environment,
      userId: this.userId,
      userEmail: this.userEmail,
      graphUrl: this.graphUrl,
      graphHeaders: this.graphHeaders,
      tenantId: this.tenantId,
      claimId: this.claimId,
    });
    if (this.onMounted) {
      this.onMounted(claim);
    }
  },
  computed: {
    isAdmin() {
      return this.mode.includes('admin_');
    },
    currentStep() {
      let step = null;
      Object.keys(this.$store.state.steps).forEach(key => {
        if (this.$store.state.steps[key].active === true) {
          step = key
        }
      });
      return step;
    }
  }
}
</script>

<style scoped>
.v-enter-active {
  transition: opacity .25s ease;
}
.v-leave-active {
  display: none;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
