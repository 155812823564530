<template>
  <form @submit.prevent="submitIncident" class="max-w-4xl">
    <div class="bg-white rounded-lg p-6 text-gray-800 mb-2" v-if="currentAdjuster">
      <div class="mb-1">
        <h3 class="font-bold text-2xl text-gray-700 mb-2">
          Information about your claim
        </h3>
        <p>
          <span class="font-bold text-gray-700">
            Reference Number
          </span>
          <br />
          <span>
            {{ incident.tpaClaimId }}
          </span>
        </p>
      </div>
      <div class="mb-1">
        <span class="font-bold text-gray-700">
          Adjuster Information
        </span>
        <br />
        <span>
        {{ currentAdjuster.fullName || `${currentAdjuster.firstName} ${currentAdjuster.lastName}`}}
        </span>
        <br />
        <span>{{ currentAdjuster.email }}</span>
        <br />
        <span>{{ currentAdjuster.phone }}</span>
      </div>
    </div>
    <div v-if="isOrp ? !incident.id : false" class="bg-white rounded-lg p-6 text-gray-800 mb-4">
      <h2 class="text-3xl font-bold text-gray-700">
        Did the incident happen during rental period?
      </h2>
      <Toggle
        class="mt-4"
        :disabled="isSubmitting || !canEdit"
        v-model="incident.rental" />
    </div>
    <div v-if="isOrp ? (incident.rental === true && !incident.id) : false">
      <div class="bg-white rounded-lg p-6 text-gray-800">
        <p class="text-gray-600 text-xl">
          <span className="font-bold">
            Lula doesn’t cover claims for incidents that occur during rental period.
          </span>
          <br />
          <span>
            You can file these claims directly on your carshare website.
          </span>
        </p>
        <div className="mt-4">
          <button
            className="bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt"
            @click="returnToDashboard">
              Return to Dashboard
          </button>
        </div>
      </div>
    </div>
    <div v-if="isOrp ? (incident.rental === false || incident.id) : true">
      <div class="my-2 p-6 bg-white rounded-lg" v-if="isAdmin && incident.id">
        <h3 class="font-bold text-xl text-gray-800">Eligible Policies </h3>
        <hr class="my-2" />
        <div>
          <div @click="onPolicyClicked(policyType.documentId)" class="border bg-white rounded-md px-4 py-2 m-1 cursor-pointer text-sm flex justify-between items-start hover:bg-gray-100 sm:text-base" v-for="policyType in policyTypes" :key="policyType.number">
            <div>
              <span class="font-bold text-gray-800">{{policyType.name}}</span><br>
              <span class="text-gray-600">{{policyType.number}}</span>
            </div>
            <div class="uppercase font-bold text-white bg-lula-alt rounded-full px-4">
              {{policyType.broker}}
            </div>
          </div> 
        </div>
      </div>
    </div>
      <div class="bg-white rounded-lg p-6 text-gray-800" v-if="isOrp ? (incident.rental === false) : true">
        <div>
          <div className="flex justify-between">
            <h2 class="text-3xl font-bold text-gray-700">
              <span v-if="incident.id">Details about your claim</span>
              <span v-else>Report a Claim</span>
            </h2>
            <div
              class="uppercase font-bold text-white bg-lula-alt rounded-full px-4 flex items-center"
              v-if="isAdmin && isOrp">
                <span>
                  {{ (account.tenantId || '').toUpperCase() || 'GETAROUND' }}
                </span>
            </div>
          </div>
          <div class="mt-1 text-gray-600" v-if="isAdmin">
            <a :href="accountLink" class="font-bold hover:underline">
              {{ account.businessLegalName || '' }}
            </a>
            <span class="mx-2 text-xl" v-if="incident.submitted">
              &#x2022
            </span>
            <span v-if="incident.submitted">
             Reported on {{ incident.submitted }}
            </span>
          </div>
          <div class="text-gray-700 my-4" v-else>
            <span v-if="incident.id" />
            <span v-else>
              If you’ve been in a vehicle accident, report a claim by
              filling out the form below. We’ll get back to you within 24 hours.
            </span>
          </div>
        </div>
        <div class="text-lg mt-2">
          <div class="border p-4 rounded mr-0 md:mr-4">
            <h3 class="text-xl font-bold text-gray-600">
              Incident information
            </h3>
            <hr class="my-4" />
            <div>
              <div class="flex items-center text-gray-600">
                <label class="block font-bold mb-1 mr-1">
                  Date of the Incident
                </label>
                <div v-tooltip="`
                  Date of the incident and closest
                  time approximation`">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="-mt-1.5 h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="
                          M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                          1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                          8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                          0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                          100-2 1 1 0 000 2z"
                          clip-rule="evenodd" />
                    </svg>
                </div>
              </div>
              <div class="flex">
                <Datepicker
                  class="w-full mr-2"
                  :max-date="todayString"
                  :input-class-name="datePickerClass"
                  :enable-time-picker="false"
                  :disabled="isSubmitting || !canEdit"
                  :hide-input-icon="!!incident.incidentDate"
                  placeholder="     Select a date"
                  format="MM/dd/yyyy"
                  autoApply
                  :closeOnAutoApply="true"
                  v-model="incident.incidentDate"
                  required />
                <Datepicker
                  class="w-full"
                  :input-class-name="datePickerClass"
                  :disabled="incident.incidentDate ? (isSubmitting || !canEdit) : true"
                  placeholder="     Select a time"
                  :max-time="todayTimeMax"
                  :hide-input-icon="!!incident.incidentTime"
                  :is24="false"
                  autoApply
                  :closeOnAutoApply="true"
                  v-model="incident.incidentTime"
                  timePicker>
                  <template #input-icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-2 h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="
                            M12 8v4l3 3m6-3a9 9 0
                            11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </template>
                </Datepicker>
              </div>
            </div>
            <div class="mt-4">
              <div class="flex items-center text-gray-600">
                <label class="block font-bold mb-1 mr-1">
                  Incident Location / Nearest Address
                </label>
                <div v-tooltip="'Select the closest location to the incident from the dropdown'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="-mt-1.5 h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="
                        M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                        1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                        8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                        0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                        100-2 1 1 0 000 2z"
                        clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
              <div class="grid grid-cols-4">
                <div class="col-span-3 mr-2">
                  <google-autocomplete
                    ref="autoCompleteRef"
                    id="incident-location-input"
                    :classname="googleAutocompleteClass"
                    :disabled="isSubmitting || !canEdit"
                    placeholder="Enter address line 1"
                    @inputChange="addressChange"
                    @placechanged="getAddressData" />
                </div>
                <input
                  class="col-span-1"
                  placeholder="Apt #"
                  type="text"
                  :class="{ 'bg-gray-300': isSubmitting }"
                  :disabled="isSubmitting || !canEdit"
                  v-model="incident.locationAddressLineTwo" />
              </div>
            </div>
            <Transition class="mt-2">
              <iframe
                width="100%"
                height="400"
                class="rounded-lg border"
                frameborder="0"
                referrerpolicy="no-referrer-when-downgrade"
                :src="mapPlaceUrl"
                allowfullscreen
                v-if="mapPlaceUrl" />
            </Transition>
            <div class="flex flex-col mt-4 md:flex-row">
              <div class="w-full md:mr-4">
                <div class="flex items-center text-gray-600">
                  <label class="block font-bold mb-1">
                    Vehicle
                  </label>
                  <div v-tooltip="'Vehicle involved in the incident'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="-mt-1.5 h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="
                          M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                          1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                          8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                          0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                          100-2 1 1 0 000 2z"
                          clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                <div>
                  <Typeahead
                    :items="vehicles"
                    :min-input-length="0"
                    :item-projection="item => {
                      return (item.key || `${item.year} ${item.make} ${item.model}`)
                        + ` (${item.vin})`;
                    }"
                    @selectItem="selectVehicle"
                    :class="typeaheadClass"
                    :default-item="currentVehicle"
                    placeholder="Select vehicle"
                    v-model="incident.vehicleId"
                    :disabled="isSubmitting || !canEdit"
                    />
                  <div class="ml-1 text-base" v-if="currentVehicle">
                    <span
                      class="hover:underline cursor-pointer"
                      @click="onViewVehicle(currentVehicle)">
                        Click to view vehicle
                    </span>
                  </div>
                </div>
              </div>
              <div class="w-full mt-4 md:mt-0" v-if="isTrucks">
                <div class="flex items-center text-gray-600">
                  <label class="block font-bold mb-1 mr-1">
                    Driver
                  </label>
                  <div v-tooltip="'If driver was involved in this incident'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="-mt-1.5 h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="
                          M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                          1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                          8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                          0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                          100-2 1 1 0 000 2z"
                          clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                <div>
                  <Typeahead
                    :items="drivers"
                    :min-input-length="0"
                    :item-projection="item => {
                      return `${item.firstName} ${item.lastName}`;
                    }"
                    @selectItem="selectDriver"
                    :default-item="currentDriver"
                    :class="typeaheadClass"
                    placeholder="Select driver"
                    v-model="incident.driver"
                    :disabled="isSubmitting || !canEdit"
                    />
                </div>
              </div>
            </div>
          </div>
          <div class="p-4 border mt-4 rounded text-gray-600 md:mr-4">
            <div>
              <h3 class="font-bold text-xl">
                Representative details
              </h3>             
            </div>
            <hr class="my-2" />
            <div>
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Name
                  </label>
                  <input
                    placeholder="Enter first name"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.representativeName" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                   Contact Name
                  </label>
                  <input
                    placeholder="Enter contact name"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.representativeContactName" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                   Contact Email
                  </label>
                  <input
                    placeholder="Enter contact email"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.representativeContactEmail" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Phone number
                  </label>
                  <input
                    placeholder="Enter phone number"
                    type="text"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    ref="driverPhoneInputRef"
                    v-model="incident.representativeContactPhone" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1" v-if="incident.representativeAddressManual !== true">
                  <label class="block font-bold mb-1">
                    Address
                  </label>
                  <google-autocomplete
                    ref="representativeAutoCompleteRef"
                    id="representative-address-input"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    placeholder="Enter address"
                    @inputChange="representativeAddressChange"
                    @placechanged="getRepresentativeAddressData" />
                  <div class="w-full flex justify-between">
                    <span />
                    <span class="text-sm cursor-pointer hover:underline" @click="incident.representativeAddressManual = true">
                      Enter manually
                    </span>
                  </div>
                </div>               
              </div>
              <div class="m-1 mb-2 col-span-2" v-if="incident.representativeAddressManual">
                <div class="grid grid-cols-4">
                  <div class="col-span-3 pr-2">
                    <label class="font-bold">
                      Address
                    </label>
                    <input
                      type="text"
                      placeholder="Enter address line 1"
                      v-model="incident.representativeAddressLineOne" />
                  </div>
                  <div class="col-span-1">
                    <label class="font-bold">&nbsp;</label>
                    <input
                      type="text"
                      placeholder="Apt #"
                      v-model="incident.representativeAddressLineTwo" />
                  </div>
                </div>
                <div class="grid grid-cols-3 gap-1 mt-2">
                  <input
                    type="text"
                    placeholder="City"
                    v-model="incident.representativeCity" />
                  <state-select v-model="incident.representativeState" />
                  <input
                    type="text"
                    placeholder="Zipcode"
                    v-model="incident.representativeZipcode" />
                </div>
              </div>              
            </div>
          </div>
          <div class="p-4 border mt-4 rounded text-gray-600 md:mr-4">
            <div>
              <h3 class="font-bold text-xl">
                Claimant details
              </h3>              
            </div>
            <hr class="my-2" />
            <div>
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    First Name
                  </label>
                  <input
                    placeholder="Enter first name"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.claimantFirstName" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Last Name
                  </label>
                  <input
                    placeholder="Enter last name"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.claimantLastName" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Email
                  </label>
                  <input
                    placeholder="Enter email"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.claimantEmail" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Phone number
                  </label>
                  <input
                    placeholder="Enter phone number"
                    type="text"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"                    
                    v-model="incident.claimantPhone" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Driver License Number
                  </label>
                  <input
                    placeholder="Enter DL #"
                    type="text"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"                    
                    v-model="incident.claimantDriverLicenseNumber" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Driver License State
                  </label>
                  <input
                    placeholder="Enter DL State"
                    type="text"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"                    
                    v-model="incident.claimantDriverLicenseState" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1" v-if="incident.claimantAddressManual !== true">
                  <label class="block font-bold mb-1">
                    Address
                  </label>
                  <google-autocomplete
                    ref="claimantAutoCompleteRef"
                    id="claimant-address-input"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    placeholder="Enter address"
                    @inputChange="claimantAddressChange"
                    @placechanged="getClaimantAddressData" />
                  <div class="w-full flex justify-between">
                    <span />
                    <span class="text-sm cursor-pointer hover:underline" @click="incident.claimantAddressManual = true">
                      Enter manually
                    </span>
                  </div>
                </div>                
              </div>
              <div class="m-1 mb-2 col-span-2" v-if="incident.claimantAddressManual">
                <div class="grid grid-cols-4">
                  <div class="col-span-3 pr-2">
                    <label class="font-bold">
                      Address
                    </label>
                    <input
                      type="text"
                      placeholder="Enter address line 1"
                      v-model="incident.claimantAddressLineOne" />
                  </div>
                  <div class="col-span-1">
                    <label class="font-bold">&nbsp;</label>
                    <input
                      type="text"
                      placeholder="Apt #"
                      v-model="incident.claimantAddressLineTwo" />
                  </div>
                </div>
                <div class="grid grid-cols-3 gap-1 mt-2">
                  <input
                    type="text"
                    placeholder="City"
                    v-model="incident.claimantCity" />
                  <state-select v-model="incident.claimantState" />
                  <input
                    type="text"
                    placeholder="Zipcode"
                    v-model="incident.claimantZipcode" />
                </div>
              </div>              
            </div>
          </div>
          <div class="border p-4 rounded mt-4 mr-0 md:mr-4" v-if="!isLimited">
            <h3 class="text-gray-600 text-xl font-bold">
              Incident details
            </h3>
            <hr class="my-4" />
          <div class="mt-4">
            <div class="flex items-center text-gray-600">
              <label class="block font-bold mb-1 mr-1">
                Description of the Incident
                <span class="font-normal text-gray-400">
                  (Minimum 100 Characters)
                </span>
              </label>
            </div>
            <div>
              <textarea
                class="transition py-2 px-4"
                :class="{ 'bg-gray-300': isSubmitting }"
                rows="5"
                :minlength="100"
                :maxlength="4000"
                placeholder="Please describe what happened"
                :disabled="isSubmitting || !canEdit"
                v-model="incident.description" />
              <div class="flex justify-between text-sm -mt-2 text-gray-400">
                <span />
                <span>
                  {{ descriptionCharacterCount }}/4000 characters
                </span>
              </div>
            </div>
            <div v-if="isLoadingFiles">
              <div class="bg-gray-50 p-4 mt-4 rounded text-gray-600 text-center animate-pulse">
                Loading Attachments
              </div>
            </div>
            <div v-else>                            
              <div class="mt-4">
                <div class="flex items-center text-gray-600">
                  <label class="block font-bold mb-1 mr-1">
                    Claim Attachments
                    <span class="hidden md:inline">
                      (Additional Documents, Damage of Vehicles, etc...)
                    </span>
                    <span class="font-normal text-gray-400">
                      (Optional)
                    </span>
                  </label>
                </div>
                <Attachments
                  ref="claimAttachments"
                  :disabled="isSubmitting || !canEdit" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 p-4 bg-white rounded-lg" v-if="isCommentNeeded">
        <label class="text-lg text-gray-600 font-bold">
          Comment
        </label>
        <br />
        <textarea
          :disabled="isSubmitting"
          :class="{ 'bg-gray-300 text-gray-400': isSubmitting }"
          placeholder="You will need to add a comment to save this change"
          v-model="incident.comment" />
      </div>
      <div class="w-full flex justify-between mt-2" v-if="!isLimited">
        <span />
        <button
          type="submit"
          class="transition duration-200"
          :class="
            {
              'bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt': canSubmit,
              'bg-gray-300 text-gray-400 font-bold cursor-default': !canSubmit,
            }
          "
          :disabled="!canSubmit"
          v-if="canEdit">
            {{ $store.state.claim?.id ? 'Update Claim' : 'Submit Claim' }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import 'intl-tel-input/build/css/intlTelInput.css';
import { ref, onMounted, computed, onUpdated, inject } from 'vue';
import { useStore } from 'vuex';
import {
  Toggle,
  Datepicker,
  Typeahead,
  GoogleAutocomplete,
  StateSelect,
  Modal,
} from '@lula-technologies-inc/lula-components';
import { useLDFlag } from 'launchdarkly-vue-client-sdk'
import IncidentAttachment from '@/ClaimForm/IncidentAttachment.vue';
import Attachments from '@/ClaimForm/Attachments.vue';
import axios from 'axios';
import moment from 'moment';
import { setTimeout } from 'timers';

function getIncidentFromClaim(claim, thirdParties = []) {
  const incidentDate = moment(moment.utc(claim.incidentDate).format('yyyy-MM-DD'), 'yyyy-MM-DD');
  const incidentTime = moment(claim.incidentTime, 'HH:mm');
  const createdDate = moment.utc(claim.created).local();
  return {

    // INCIDENT FIELDS
    id: claim.id,
    incidentDate: claim.incidentDate ? incidentDate.toDate() : null,
    incidentTime: claim.incidentTime ? {
      hours: incidentTime.format('HH'),
      minutes: incidentTime.format('mm'),
    }: null,
    locationAddressLineOne: claim.locationAddressLineOne || '',
    locationAddressLineTwo: claim.locationAddressLineTwo || '',
    locationCity: claim.locationCity || '',
    locationZipcode: claim.locationZipcode || '',
    locationState: claim.locationState || '',
    vehicleId: claim.vehicleId || null,
    representativeName: claim.representativeName,
    representativeContactName: claim.representativeContactName,
    representativeContactEmail: claim.representativeContactEmail,
    representativeContactPhone: claim.representativeContactPhone,
    representativeAddressLineOne: claim.representativeAddressLineOne,
    representativeAddressLineTwo: claim.representativeAddressLineTwo,
    representativeCity: claim.representativeCity,
    representativeState: claim.representativeState,
    representativeZipcode: claim.representativeZipcode,
    claimantFirstName: claim.claimantFirstName,
    claimantLastName: claim.claimantLastName,
    claimantEmail: claim.claimantEmail,
    claimantPhone: claim.claimantPhone,
    claimantAddressLineOne: claim.claimantAddressLineOne,
    claimantAddressLineTwo: claim.claimantAddressLineTwo,
    claimantCity: claim.claimantCity,
    claimantState: claim.claimantState,
    claimantZipcode: claim.claimantZipcode,
    claimantDriverLicenseNumber: claim.claimantDriverLicenseNumber,
    claimantDriverLicenseState: claim.claimantDriverLicenseState,
    rental: claim.rental || false,
    description: claim.description || '',
    submitted: claim.created ? createdDate.format('dddd MMMM Do YYYY') : null,  
    // ADMIN FIELDS
    status: claim.status || 'reported',
    tpaClaimId: claim.tpaClaimId || null,
    adjuster: claim.adjuster || null,
    // META FIELDS
    comment: '',
    '__isSubmitting': false
  }
}

export default {
  components: {
    Toggle,
    Typeahead,
    IncidentAttachment,
    Attachments,
    Datepicker,
    StateSelect,
    GoogleAutocomplete,
    Modal,
  },
  setup() {

    const store = useStore();

    const mode = inject('mode') || 'cars';
    const isAdmin = mode.includes('admin_');
    const isOrp = mode.includes('orp_');
    const isLimited = mode.includes('limited_');
    const tenantId = inject('tenantId') || null;
    const mapsApiKey = inject('mapsApiKey') || null;
    const showMessage = inject('showMessage') || (() => null);
    const onClaimSubmit = inject('onClaimSubmit') || (() => null);
    const onViewVehicle = inject('onViewVehicle') || (() => null);
    const accountLink = inject('accountLink') || '#';
    const account = inject('account') || {};
    const vehicles = inject('vehicles') || [];
    const adjusters = JSON.parse(inject('adjustersJson') || '[]');
    const drivers = inject('drivers') || [];
    const onPolicyClicked = inject('onPolicyClicked') || (() => null);

    const captchaToken = inject('captchaToken') || null;
    const isVerified = ref(captchaToken ? false : true);

    const currentVehicle = computed(() => {
      return vehicles.find(vin => {
        return vin.entityId === incident.value.vehicleId;
      });
    });

    onMounted(() => {
      if (captchaToken) {
        captchaToken.subscribe({
          next(token) {
            isVerified.value = token ? true : false;
          }
        });
      }
    });
    
    const incident = ref(getIncidentFromClaim({ ...store.state.claim}));
        
    const currentAdjuster = computed(() => {
      if (!incident.value.adjuster)
        return null;
      return adjusters.find(adj => adj.id === incident.value.adjuster);
    })

    const initialIncident = ref({ ...incident.value });

    const setInitialValues = () => {
      initialIncident.value.comment = '';
      initialIncident.value = { ...incident.value };      
    }

    const isLoadingFiles = ref(false);

    onMounted(async () => {
      if (store.state.claim?.id == null) {
        return;
      }
      isLoadingFiles.value = true;
      await store.dispatch('getAttachmentsForClaim', {
        tenantId,
        claimId: store.state.claim.id,
      });
      isLoadingFiles.value = false;
    });

    onMounted(() => {
      setInitialValues();     
    });
 
    const toggleSubmitting = () => {
      store.commit('setItem', {
        key: 'isSubmittingForm',
        value: !store.state.isSubmittingForm
      });
    }

    const hasChanged = computed(() => {
      if(Object.keys(initialIncident.value).find(key =>
        initialIncident.value[key] !== incident.value[key]
      ))
        return true
      if ((store.state.attachments || []).find(a => !a.object)) {
        return true;
      }
      return false;
    });

    const isCommentNeeded = computed(() => {
        if (!isAdmin) {
          return false;
        }
        if (!store.state.claim?.id) {
          return false;
        }
        return hasChanged.value;
      })

    const canSubmit = computed(() => {
      if (!isVerified.value)
        return false;
      if (!hasChanged.value)
        return false;
      if (!currentVehicle.value)
        return false;
      if (incident.value.description?.length < 100)
        return false;
      if (isCommentNeeded.value && !incident.value.comment)
        return false;
      return !store.state.isSubmittingForm &&
        incident.value.vehicleId &&
        incident.value.locationAddressLineOne &&
        incident.value.incidentDate &&
        incident.value.description
    });

    const mapPlaceId = ref(null);
    const autoCompleteRef = ref(null);
    onMounted(() => {
      if (!incident.value.id) {
        return;
      }
      if (!autoCompleteRef.value) {
        return;
      }
      if (!incident.value.locationAddressLineOne) {
        return;
      }
      autoCompleteRef.value.update([
        incident.value.locationAddressLineOne,
        incident.value.locationCity,
        incident.value.locationZipcode,
        incident.value.locationState
      ].join(', '));
    });
    const representativeAutoCompleteRef = ref(null);
    onMounted(() => {
      if (!incident.value.id) {
        return;
      }
      if (!representativeAutoCompleteRef.value) {
        return;
      }
      if (!incident.value.representativeAddressLineOne) {
        return;
      }
      representativeAutoCompleteRef.value.update([
        incident.value.representativeAddressLineOne,
        incident.value.representativeCity,
        incident.value.representativeZipcode,
        incident.value.representativeState,
      ].join(', '));
    });
    const claimantAutoCompleteRef = ref(null);
    onMounted(() => {
      if (!incident.value.id) {
        return;
      }
      if (!claimantAutoCompleteRef.value) {
        return;
      }
      if (!incident.value.claimantAddressLineOne) {
        return;
      }
      claimantAutoCompleteRef.value.update([
        incident.value.claimantAddressLineOne,
        incident.value.claimantCity,
        incident.value.claimantZipcode,
        incident.value.claimantState,
      ].join(', '));
    });    
    const claimAttachments = ref(null);
    return {
      isAdmin,
      isOrp,
      isLimited,
      isLoadingFiles,      
      claimAttachments,
      incident,
      canSubmit,    
      account,
      vehicles,      
      accountLink,      
      autoCompleteRef,  
      representativeAutoCompleteRef,
      claimantAutoCompleteRef,    
      onViewVehicle,
      onPolicyClicked,      
      currentAdjuster,      
      isCars: computed(() => {
        return mode.includes('cars');
      }),
      isTrucks: computed(() => {
        return mode.includes('trucks');
      }),
      isCommentNeeded,
      descriptionCharacterCount: computed(() => {
        if (incident.value.description) {
          return `${incident.value.description}`.length;
        }
        return 0;
      }),
      mapPlaceUrl: computed(() => {
        if (!mapsApiKey) {
          return null;
        }
        const baseUrl =
          'https://www.google.com/maps/embed/v1/place';
        if (mapPlaceId.value) {
          return `${baseUrl}?key=${mapsApiKey}&q=place_id:${mapPlaceId.value}`;
        }
        if (incident.value.locationAddressLineOne) {
          const query = [
            incident.value.locationAddressLineOne,
            incident.value.locationCity,
            incident.value.locationZipcode,
            incident.value.locationState
          ].join(', ');
          return `${baseUrl}?key=${mapsApiKey}&q=${escape(query)}`;
        }
        return null;
      }),
      canEdit: computed(() => {
        if (isLimited) {
          return false;
        }
        if (isAdmin) {
          return true;
        }
        if (store.state.claim?.id) {
          return store.state.claim.status === 'reported';
        }
        return true;
      }),
      isSubmitting: computed(() => {
        return store.state.isSubmittingForm;
      }),
      todayString: computed(() => {
        const today = moment();
        return today.format('yyyy-MM-DD');
      }),
      todayTimeMax: computed(() => {
        const today = moment();
        const incidentDate = moment(incident.value.incidentDate);
        if (incidentDate.format('yyyy-MM-DD') === today.format('yyyy-MM-DD'))
          return { hours: parseInt(today.format('H')), minutes: parseInt(today.format('m')) };
        return null;
      }),      
      representativeAddressChange({ newVal }) {
        if (!newVal.trim()) {
          incident.value = {
            ...incident.value,
            representativeAddressLineOne: '',
            representativeCity: '',
            representativeZipcode: '',
            representativeState: ''
          }
        }
      },
      claimantAddressChange({ newVal }) {
        if (!newVal.trim()) {
          incident.value = {
            ...incident.value,
            claimantAddressLineOne: '',
            claimantCity: '',
            claimantZipcode: '',
            claimantState: ''
          }
        }
      },
      addressChange({ newVal }) {
        if (!newVal.trim()) {
          mapPlaceId.value = null;
          incident.value = {
            ...incident.value,
            locationAddressLineOne: '',
            locationCity: '',
            locationZipcode: '',
            locationState: ''
          }
        }
      },     
      getRepresentativeAddressData(address) {
        incident.value = {
          ...incident.value,
          representativeAddressLineOne: `${address['street_number'] || ''} ${address['route'] || ''}`,
          representativeCity: address['locality'] || '',
          representativeZipcode: address['postal_code'] || '',
          representativeState: address['administrative_area_level_1'] || '',
        }
      },
      getClaimantAddressData(address) {
        incident.value = {
          ...incident.value,
          claimantAddressLineOne: `${address['street_number'] || ''} ${address['route'] || ''}`,
          claimantCity: address['locality'] || '',
          claimantZipcode: address['postal_code'] || '',
          claimantState: address['administrative_area_level_1'] || '',
        }
      },
      getAddressData(address, place) {
        if (place['place_id']) {
          mapPlaceId.value = place['place_id'];
        }
        incident.value = {
          ...incident.value,
          locationAddressLineOne: `${address['street_number'] || ''} ${address['route'] || ''}`,
          locationCity: address['locality'] || '',
          locationZipcode: address['postal_code'] || '',
          locationState: address['administrative_area_level_1'] || '',
        }
      },
      googleAutocompleteClass: computed(() => {
        let className = `
          w-full transition py-2 px-4
        `;
        if (incident.value['__isSubmitting']) {
          className += ' bg-gray-300';
        }
        return className;
      }),
      typeaheadClass: computed(() => {
        let className = `
          transition py-2 px-4
        `;
        if (incident.value['__isSubmitting']) {
          className += ' bg-gray-300 cursor-default';
        }
        return className;
      }),
      datePickerClass: computed(() => {
        let className = `
          transition w-full bg-gray-100 rounded-lg text-gray-700
          border hover:border text-lg p-2 md:mr-4`;
        if (incident.value['__isSubmitting']) {
          className += ' bg-gray-300 cursor-default';
        }
        return className;
      }),
      selectVehicle(vehicle) {
        incident.value.vehicleId = vehicle.entityId;
      },     
      currentVehicle,
      async downloadFile(url, name) {
        const download = await axios({ url, responseType: 'blob' });
        const downloadUrl = URL.createObjectURL(download.data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      },
      returnToDashboard() {
        window.location.href = '/';
      },
      policyTypes: computed(() => {
        return store.state.policyTypes || [];
      }),          
      async submitIncident() {        
        if (!canSubmit.value) {
          return;
        }
        if (!isVerified.value) {
          return;
        }
        toggleSubmitting();
        try {
          const comment = incident.value.comment;
          const incidentDate =
            moment(incident.value.incidentDate).local();
          const incidentTime =
            moment().local();
          if (incident.value.incidentTime) {
            incidentTime.hours(incident.value.incidentTime.hours);
            incidentTime.minutes(incident.value.incidentTime.minutes);
          }                   
          const claimId = store.state.claim?.id;
          const claim = await store.dispatch('saveClaim', {
            ...incident.value,
            incidentDate: incidentDate.utc().format(),
            incidentTime: incident.value.incidentTime ?
              incidentTime.format('HH:mm') : null,           
            id: claimId,
            tenantId,
          });
          if (!claim) {
            showMessage('Failed to submit claim', true);
            return;
          }          
          await Promise.all((store.state.attachments || []).map(attachment => {
            if (!attachment.object) {
              return store.dispatch(
                'saveAttachmentForClaim',
                { claimId: store.state.claim.id, tenantId, attachment }
              );
            }
            return null;
          }));         
          await store.dispatch(
            'getAttachmentsForClaim',
            { claimId: store.state.claim.id, tenantId }
          );          
          await claimAttachments.value.loadAttachments();
          setInitialValues();
          onClaimSubmit(claim);
          if (!isAdmin && !claimId) {
            store.commit('setStep', 'confirmation');
          }
        } catch(err) {
          console.log(err);
          await store.dispatch('sendError', err);
          showMessage('Failed to submit claim', true);
        } finally {
          toggleSubmitting();
        }
      }
    }
  }
}
</script>

<style scoped>
.iti__flag {background-image: url("/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/flags@2x.png");}
}

.v-enter-active,
.v-leave-active {
  transition: opacity .25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
