<template>
  <div>
    <div class="text-gray-600">
      <div>
        <button
          class="
            transition-all duration-300 w-fit flex
            items-center justify-center text-gray-600
            bg-gray-200 hover:bg-gray-300"
          type="button"
          @click="$refs.attachmentRef.click()"
          v-if="!attachment">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          <span>
            Add {{ label || 'Attachment' }}
          </span>
        </button>
        <div
          class="
            flex items-center cursor-pointer
            hover:text-gray-700"
          @click="removeAttachment"
          v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mt-.5 mr-4 md:mr-1">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
            <span>
              {{ attachment.name }}
            </span>
        </div>
      </div>
    </div>
    <input
      ref="attachmentRef"
      type="file"
      accept="image/png, image/jpeg, image/jpg, application/pdf"
      :multiple="false"
      class="hidden" />
  </div>
</template>

<script>
export default {
  props: [
    'value',
    'label',
  ],
  data() {
    return {
      attachment: this.value,
    }
  },
  mounted() {
    if (this.$refs.attachmentRef) {
      this.$refs.attachmentRef.addEventListener('change', () => {
        for(const file of this.$refs.attachmentRef.files) {
          this.attachment = file;
          this.$emit('input', file);
        }
      })
    }
  },
  methods: {
    removeAttachment() {
      if (confirm('Remove this attachment?')) {
        this.$refs.attachmentRef.value = null;
        this.attachment = null;
        this.$emit('input', null);
      }
    }
  }
}
</script>
