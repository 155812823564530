<template>
  <div class="m-1 bg-white border rounded-md p-4" v-if="gridClaims">
    <div class="border-lula border-l-4 bg-lula-light py-2.5 p-4 mb-2" v-if="tenantId && isAdmin && !isThirdParty">
      <span class="block font-bold text-gray-600">
        Location Code
      </span>
      <span class="text-lula-dark tracking-wider font-mono">
        {{ locationCode }}
      </span>
    </div>
    <div class="hidden w-full flex text-lg text-gray-500 mb-4 md:block">
      <span
        :class="{
          'text-lula': filter === null,
          'hover:text-gray-700': filter !== null
        }"
        class="mr-4 cursor-pointer pb-1"
        @click="filter = null">
          All Claims
      </span>
      <span 
        :class="{
          'text-lula': filter === 'open',
          'hover:text-gray-700': filter !== 'open'
        }"
        class="mr-4 cursor-pointer pb-1"
        @click="filter = 'open'">
          Open Claims
      </span>
      <span 
        :class="{
          'text-lula': filter === 'closed',
          'hover:text-gray-700': filter !== 'closed'
        }"
        class="mr-4 cursor-pointer pb-1"
        @click="filter = 'closed'">
          Closed Claims
      </span>
    </div>
    <table class="w-full">
      <tr class="border-b text-left text-gray-600">
        <th class="pb-2 font-bold w-full md:w-60">
          <span class="hidden md:inline">
            Claim ID
          </span>
          <span class="md:hidden">
            Claim
          </span>
        </th>
        <th class="hidden pb-2 font-light w-60 md:table-cell" v-for="col in columns" :key="col.label">
          {{ col.label }}
        </th>
      </tr>
      <tr>
        <td colspan="100">
          <input
            class="
              w-full border-none rounded-none -mt-1
              py-1 px-2 bg-gray-50 text-gray-600 text-lg"
            placeholder="Search claims"
            type="text"
            v-model="claimSearch" />
        </td>
      </tr>
      <tr
        class="
          cursor-pointer text-gray-600
          hover:bg-gray-100"
        v-for="claim in gridClaims"
        :key="claim.id"
        @click="(evt) => selectClaim ? selectClaim(evt, claim) : null"
        v-if="gridClaims.length > 0">
          <td class="py-4 text-gray-500 pl-2">
            <div class="w-full flex justify-between md:hidden">
              <div class="flex flex-col">
                <span class="font-bold" v-if="claim.tpaClaimId">
                  {{ claim.tpaClaimId }}
                </span>
                <span
                  class="
                    w-36 bg-lula-alt px-2 text-white
                    rounded-full text-center mb-1"
                  v-else>
                    Claim ID Pending
                </span>
                <span v-if="claim.vehicle">
                  {{ claim.vehicle.key || claim.vehicle.vin || '' }}
                </span>
                <span v-if="claim.company">
                  {{ claim.company.businessLegalName || ''}}
                </span>
              </div>
              <div>
                <span>
                  {{ claim.created }}
                </span>
              </div>
            </div>
            <div class="hidden md:flex items-center">
                <span class="font-bold" v-if="claim.tpaClaimId">
                  {{ claim.tpaClaimId }}
                </span>
                <span
                  class="
                    w-48 bg-lula-alt py-1 px-4 text-white
                    rounded-full text-center font-bold flex items-center"

                  v-else>
                    <span class="w-full">Claim ID Pending</span>
                </span>
                <span
                  class="hidden mx-2 text-gray-700 md:block"
                  v-tooltip="`
                    We are waiting for our third party claims administrator
                    to share claim ID in one business day.`"
                  v-if="!claim.tpaClaimId">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-6 h-6">
                      <path
                        fill-rule="evenodd"
                        d="
                          M2.25 12c0-5.385 4.365-9.75
                          9.75-9.75s9.75 4.365 9.75
                          9.75-4.365 9.75-9.75 9.75S2.25 17.385
                          2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255
                          0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272
                          5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75
                          0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75
                          0 01-1.5 0v-.75c0-1.279 1.06-2.107
                          1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857
                          0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd" />
                    </svg>
                </span>
            </div>
          </td>
          <td
            :class="col.class || ''"
            class="py-4 text-gray-500"
            v-for="col in columns"
            :key="col.label"
            v-if="gridClaims.length > 0">
              <div class="hidden md:inline">
                <span>
                  {{ col.format(claim) || '' }}
                </span>
              </div>
          </td>
      </tr>
      <tr
        class="cursor-pointer hover:bg-gray-100"
        @click="currentIdx += 5"
        v-if="isMorePages && gridClaims.length > 0">
          <td class="p-2 text-gray-600 text-sm md:text-base">
            View More
          </td>
          <td />
          <td />
          <td />
      </tr>
      <tr>
        <td colspan="100">
          <div class="text-center p-4" v-if="gridClaims.length === 0">
            <span class="text-gray-600">
              No Claims
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from 'moment';

function mapClaimStatus(claimStatus) {
  switch(claimStatus) {
    case 'reported': {
      return 'Reported';
    }
    case 'open': {
      return 'Open';
    }
    case 'closed': {
      return 'Closed';
    }
    case 'duplicate-closed': {
      return 'Duplicate Closed';
    }
    default: {
      return claimStatus;
    }
  }
}

export default {
  inject: [
    'environment',
    'userId',
    'userEmail',
    'graphUrl',
    'graphHeaders',
    'companies',
    'vehicles',
    'selectClaim',
    'onMounted',
    'vehicleId',
    'tenantId',
    'mode'
  ],
  mounted() {           
    const claimsResult = this.$store.dispatch('initialize', {
      graphUrl: this.graphUrl,
      graphHeaders: this.graphHeaders,
      tenantId: this.tenantId,
      mode: this.mode,
    });
    claimsResult.then(claims => {
      if (this.onMounted) {
        this.onMounted(claims);
      }
    });
  },
  data() {
    return {
      filter: null,
      currentIdx: 5,
      claimSearch: '',
    }
  },
  computed: {
    locationCode() {
      if (!this.tenantId)
        return '';
      return window.btoa(unescape(encodeURIComponent(this.tenantId))).substr(0, 15).toUpperCase();
    },
    isAdmin() {
      return (this.mode || '').includes('admin');
    },
    isThirdParty(){
      return (this.mode || '').includes('thirdparty')
    },
    columns() {
      if(this.isAdmin && !this.tenantId) {
        return [
          {
            label: 'Company',
            format: claim =>
              claim.company?.businessLegalName
          },
          {
            label: 'Submitted',
            format: claim =>
              claim.created
          },
          {
            label: 'Status',
            class: 'text-lula font-bold',
            format: claim =>
              mapClaimStatus(claim.status)
          },
        ]
      } else {
        return [
          {
            label: 'Vehicle',
            format: claim => {
              return claim.vehicle?.key || claim.vehicle?.vin || '';
            }
          },
          {
            label: 'Submitted',
            format: claim =>
              moment.utc(claim.created).local().format('MM/DD/yyyy')
          },
          {
            label: 'Status',
            class: 'text-lula font-bold',
            format: claim =>
              mapClaimStatus(claim.status)
          },
        ]
      }
    },
    isMorePages() {
      if (this.claims && !this.claimSearch) {
        return this.currentIdx < this.claims.length;
      }
      return false;
    },
    claims() {
      const claims = this.$store.state.claims;
      if (Array.isArray(claims)) {
        return claims
          .map(clm => {
            const vehicle =
              (this.vehicles || []).find(v =>
                v.entityId === (this.isThirdParty ? clm.vehicleId : clm.vehicle)
              );
            const company =
              (this.companies || []).find(company =>
                company.entityId === (this.isThirdParty ? clm.tenentId : clm.tenant)
              );
            const createdDate =
              moment.utc(clm.created).local();
            return {
              ...clm,
              vehicle,
              company,
              createdDate,
              created: createdDate.format('MM/DD/yyyy')
            }
          })
          .filter(clm =>
            this.vehicleId ? this.vehicleId === clm.vehicle?.entityId : true
          )
      }
      return null;
    },
    gridClaims() {
      if (this.claims) {
        return this.claims
          .filter(clm => {
            switch(this.filter) {
              case 'open': {
                return clm.status !== 'closed';
              }
              case 'closed': {
                return clm.status === 'closed';
              }
              default: {
                return true;
              }
            }
          })
          .filter(clm => {
            if (this.claimSearch) {
              const search = this.claimSearch.toLowerCase();
              return clm.company?.businessLegalName.toLowerCase().includes(search) ||
                clm.vehicle?.vin.toLowerCase().includes(search) ||
                clm.vehicle?.key.toLowerCase().includes(search) ||
                clm.tpaClaimId.toLowerCase().includes(search)
            }
            return true;
          })
          .sort((a, b) =>
            a.createdDate.toDate() < b.createdDate.toDate() ? 1 : -1
          )
          .filter((_, idx) => {
            if (this.claimSearch.trim()) {
              return true;
            }
            return idx < this.currentIdx;
          })
      }
      return null;
    }
  },
}
</script>
