import { createStore } from 'vuex';
import { getApolloClient, getClaims, getThirdPartyClaims } from '@/service';
import { createLogger, setUser} from '@/logger';

function getModeSettings(mode) {
  const isAdmin = mode.includes('admin');
  const type = mode.split('_').pop();
  return [ isAdmin, type ];
}

export default createStore({
  state: {},
  mutations: {
    setItem(state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {
    async initialize({
      state,
      commit,
    }, {
      environment,
      userId,
      userEmail,
      graphUrl,
      graphHeaders,
      mode,
      demo,
      claimId,
      tenantId,
    }) {
      const logger = createLogger({ environment });
      commit('setItem', {
        key: 'logger',
        value: logger,
      });
      if (userId && userEmail) {
        setUser(logger, userId, userEmail);
      }
      commit('setItem', {
        key: 'apollo',
        value: getApolloClient(graphUrl, graphHeaders),
      });
      commit('setItem', {
        key: 'mode',
        value: mode,
      });
      commit('setItem', {
        key: 'demo',
        value: demo,
      });
      commit('setItem', {
        key: 'tabName',
        value: 'firstparty'
      });
      const [ isAdmin, type ] = getModeSettings(mode);
      const claims = await getClaims(state.apollo, {
        tenantId,
        isAdmin,
        type,
        demo
      });
      commit('setItem', {
        key: 'claims',
        value: claims,
      });
      return claims;
    },
    async setTabName({state, commit}, tabName){
      commit('setItem', {
        key: 'tabName',
        value: tabName
      });
    },
    async getClaims({ state, commit }){
      const [ isAdmin, type ] = getModeSettings(state.mode);
      const claims = await getClaims(state.apollo, {
        tenantId:null,
        isAdmin,
        type,
        demo: state.demo
      });
      commit('setItem', {
        key: 'claims',
        value: claims,
      });
      return claims;
    },
    async getThirdPartyClaims({ state, commit }){
      const [ isAdmin, type ] = getModeSettings(state.mode);
      const thirdPartyClaims = await getThirdPartyClaims(state.apollo, {
        tenantId:null,
        isAdmin,
        type,
        demo: state.demo
      });
      commit('setItem', {
        key: 'claims',
        value: thirdPartyClaims,
      });
      return thirdPartyClaims;
    },
    async getClaimByTpaId({ state, commit }, tpaClaimId) {
      const [ isAdmin, type ] = getModeSettings(state.mode);
      var claims = null;
      if(state.tabName === 'firstparty')
      {
      claims = await getClaims(state.apollo, {
        tenantId: null,
        isAdmin,
        type,
        tpaClaimId,
        demo: state.demo,
      });
    }
      else{
       claims =  await getThirdPartyClaims(state.apollo, {
          tenantId: null,
          isAdmin,
          type,
          tpaClaimId,
          demo: state.demo,
        });
      }
      commit('setItem', {
        key: 'claims',
        value: claims,
      });
      return claims;
    },
    async getThirdPartyClaimByTpaId({ state, commit }, tpaClaimId) {
      const [ isAdmin, type ] = getModeSettings(state.mode);
      const tpClaims = await getThirdPartyClaims(state.apollo, {
        tenantId: null,
        isAdmin,
        type,
        tpaClaimId,
        demo: state.demo,
      });
      commit('setItem', {
        key: 'claims',
        value: tpClaims,
      });
      return tpClaims;
    },
    async viewMore({ state, commit }) {
      const [ isAdmin, type ] = getModeSettings(state.mode);
      var claims = null;
      if(state.tabName === 'firstparty')
      {
        claims = await getClaims(state.apollo, {
          tenantId: null,
          isAdmin,
          type,
          after: state.claims.length > 0 ?
            state.claims[state.claims.length - 1].id : null,
          demo: state.demo,
        });
      }
      else
      {
        claims = await getThirdPartyClaims(state.apollo, {
          tenantId: null,
          isAdmin,
          type,
          after: state.claims.length > 0 ?
            state.claims[state.claims.length - 1].id : null,
          demo: state.demo,
        });
      }
      commit('setItem', {
        key: 'claims',
        value: [ ...state.claims, ...claims ],
      });
      return claims;
    }
  }
});
