import { createStore } from 'vuex';
import {
  getApolloClient,
  getClaimById,
  getThirdPartyClaimById,
  saveClaim,
  getThirdPartiesForClaim,
  saveThirdPartyForClaim,
  getChangelogForClaim,
  getAttachmentsForClaim,
  saveAttachmentForClaim,
  getClaimExport,
  sendFnolToTpa,
  getPolicyTypesForClaim,
  validatePhoneNumber,
  convertThirdPartyClaimObjectToClaimObject,
  getPolicyTypesForThirdPartyClaim,
  getAttachmentsForThirdPartyClaim,
  saveAttachmentForThirdPartyClaim,
  saveThirdPartyClaim,
  getChangelogForThirdPartyClaim,
  getClaims,
} from '@/service';
import {
  createLogger,
  setUser,
  sendError
} from '@/logger';

export default createStore({
  state: {
    steps: {
      'incident': {
        index: 1,
        hidden: false,
        label: 'Incident Information',
        admin: false,
        active: true,
      },
      'confirmation': {
        index: 2,
        hidden: true,
        label: 'Confirm',
        admin: false,
        active: false
      },
      'admin': {
        index: 3,
        hidden: false,
        label: 'Admin',
        admin: true,
        active: false
      }
    },
    attachments: [],
    isSubmittingForm: false,
  },
  mutations: {
    setStep(state, key) {
      const steps = state.steps;
      Object.keys(steps).forEach(kStep => {
        steps[kStep].active = false;
      });
      steps[key].active = true;
      state.steps = steps;
    },
    setItem(state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {
    async initialize({
      state,
      commit,
      dispatch,
    }, {
      environment,
      userId,
      userEmail,
      graphUrl,
      graphHeaders,
      tenantId,
      claimId,
    }) {
      const logger = createLogger({ environment });
      commit('setItem', {
        key: 'logger',
        value: logger,
      });
      if (userId && userEmail) {
        setUser(logger, userId, userEmail);
      }
      commit('setItem', {
        key: 'apollo',
        value: getApolloClient(graphUrl, graphHeaders),
      });
      if (claimId) {
      const claim = await dispatch(
          'getClaimById',
          { claimId, tenantId },
        ); 
        await dispatch(
          'getThirdPartiesForClaim',
          { claimId, tenantId }
        );
        await dispatch(
          'getAttachmentsForClaim',
          { claimId, tenantId }
        );
        await dispatch(
          'getPolicyTypesForClaim',
          { claimId, tenantId }
        );
        return claim;
      } else {
        commit('setItem', {
          key: 'claim',
          value: {},
        });
        commit('setItem', {
          key: 'isThirdPartyClaim',
          value: false,
        });
        return {};
      }
    },
    async getClaimById({ commit, state }, { claimId, tenantId }) {
      var claim = await getClaimById(state.apollo, claimId, tenantId);     
      if(!claim)
      {        
        claim = await getThirdPartyClaimById(state.apollo, claimId, tenantId);
        claim = await convertThirdPartyClaimObjectToClaimObject(claim);
        commit('setItem', {
          key: 'isThirdPartyClaim',
          value: true,
        });
      }
      else
      {
        commit('setItem', {
          key: 'isThirdPartyClaim',
          value: false,
        });
      }
      commit('setItem', {
        key: 'claim',
        value: claim,
      });
      return claim;
    },
    async saveClaim({ state, commit }, claim) {
      var save = null;
      if(!state.isThirdPartyClaim)
      {
        save = await saveClaim(
          state.apollo,
          claim
        );
      }
      else
      {
        save = await saveThirdPartyClaim(
          state.apollo,
          claim
        ); 
      }
      if (save) {
        commit('setItem', {
          key: 'claim',
          value: save,
        });
      }
      return save;
    },
    async getThirdPartiesForClaim({ state, commit }, { claimId, tenantId }) {
      var thirdParties = null;
      if(!state.isThirdPartyClaim)
      {
        thirdParties = await getThirdPartiesForClaim(
          state.apollo,
          claimId,
          tenantId
        );
        commit('setItem', {
          key: 'thirdParties',
          value: thirdParties,
        });
      }
      return thirdParties;
    },
    async saveThirdPartyForClaim({ state, commit }, thirdParty) {      
      return saveThirdPartyForClaim(
        state.apollo,
        state.claim.id,
        thirdParty,
      );
    },
    async getChangelogForClaim({ state, commit }, { claimId, tenantId }) {
      var changelog = null;
      if(!state.isThirdPartyClaim)
      {
        changelog = await getChangelogForClaim(
          state.apollo,
          claimId,
          tenantId,
        );
      }
      else
      {
        changelog = await getChangelogForThirdPartyClaim(
          state.apollo,
          claimId,
          tenantId,
        );
      }
      commit('setItem', {
        key: 'changelog',
        value: changelog,
      });
      return changelog;
    },
    async getAttachmentsForClaim({ state, commit }, { claimId, tenantId }) {
      let attachments = [];
      if (!state.isThirdPartyClaim)
      {
        attachments = await getAttachmentsForClaim(
          state.apollo,
          claimId,
          tenantId,
        );
      }
      else
      {
        attachments = await getAttachmentsForThirdPartyClaim(
          state.apollo,
          claimId,
          tenantId,
        );
      }
      const attachmentsToSort = [...attachments]
      attachmentsToSort.sort(function(a, b) {
        const fileNameA = a.name.toUpperCase();
        const fileNameB = b.name.toUpperCase();
        return (fileNameA < fileNameB) ? -1 : 1;
      });
      commit('setItem', {
        key: 'attachments',
        value: attachmentsToSort,
      });
      return attachmentsToSort;
    },
    async getPolicyTypesForClaim({ state, commit }, { claimId, tenantId }){      
      let policyTypes = null;
      if(!state.isThirdPartyClaim)
      {
        policyTypes = await getPolicyTypesForClaim(
          state.apollo,
          claimId,
          tenantId,
        );
      }
      else
      {
        policyTypes = await getPolicyTypesForThirdPartyClaim(
          state.apollo,
          claimId,
          tenantId,
        );
      }
      commit('setItem', {
        key: 'policyTypes',
        value: policyTypes,
      });
      console.log("policytypes", policyTypes);
      return policyTypes;
    },
    async saveAttachmentForClaim({ state, commit }, { claimId, tenantId, attachment }) {
      var save = null;
      if(!state.isThirdPartyClaim)
      {
        save = await saveAttachmentForClaim(
          state.apollo,
          claimId,
          attachment,
          tenantId
        );
      }
      else
      {
        save = await saveAttachmentForThirdPartyClaim(
          state.apollo,
          claimId,
          attachment,
          tenantId
        );
      }
      return save;
    },
    async getClaimExport({ state }, { claimId, tenantId }) {
      return getClaimExport(
        state.apollo,
        claimId,
        tenantId,
      );
    },
    async sendFnolToTpa({ state }, { claimId, tenantId }) {
      return sendFnolToTpa(
        state.apollo,
        claimId,
        tenantId,
      )
    },
    async checkForDuplicateClaim({state}, {tenantId, incidentDate, vehicleId}){
      const duplicateClaims = await getClaims(state.apollo, {
        tenantId,
        incidentDate,
        vehicleId
      });
      if(duplicateClaims && duplicateClaims.length > 0)
        return true;
      else
        return false;
    },
    async sendError({ state }, error) {
      if (!state.logger)
        return null;
      return sendError(state.logger, error);
    },
    async validatePhoneNumber({state}, { phoneNumber }) {
      return validatePhoneNumber( state.apollo, phoneNumber);
    }
  },
});
