import { createStore } from 'vuex';
import { getApolloClient, getClaims, getThirdPartyClaims } from '@/service';
import { createLogger, setUser } from '@/logger';

function getModeSettings(mode) {
  const isAdmin = mode.includes('admin');
  const isThirdParty = mode.includes('thirdparty')
  const type = mode.split('_').pop();
  return [ isAdmin, type, isThirdParty ];
}

export default createStore({
  state: {},
  mutations: {
    setItem(state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {
    async initialize({
      state,
      commit,
    }, {
      environment,
      userId,
      userEmail,
      graphUrl,
      graphHeaders,
      mode,
      claimId,
      tenantId,
    }) {
      const logger = createLogger({ environment });
      commit('setItem', {
        key: 'logger',
        value: logger,
      });
      if (userId && userEmail) {
        setUser(logger, userId, userEmail);
      }
    
      commit('setItem', {
        key: 'apollo',
        value: getApolloClient(graphUrl, graphHeaders),
      });
      const [ isAdmin, type, isThirdParty ] = getModeSettings(mode);
      var claims = null;
      if(!isThirdParty)
      {
        claims = await getClaims(state.apollo, {
          tenantId,
          isAdmin,
          type
        });
      }
      else
      {
        claims = await getThirdPartyClaims(state.apollo, {
          tenantId,
          isAdmin,
          type
        });
      }    
      commit('setItem', {
        key: 'claims',
        value: claims,
      });
      return claims;
    },
  }
});
