<template>
  <div
    :class="{
      'bg-gray-300': disabled
    }"
    ref="dropZone"
    class="
      transition w-full flex flex-col p-4 pb-2 justify-center
      border-dashed border-2 rounded-xl md:max-w-xs">
        <button
          @click="fileInput.click()"
          :disabled="disabled"
          type="button"
          class="bg-gray-200 text-gray-600 hover:bg-gray-300">
            Upload Files (optional)
        </button>
        <div class="text-center my-2 text-gray-400">
          Or drag and drop
        </div>
        <input
          ref="fileInput"
          :accept="accept"
          :multiple="multiple"
          type="file"
          class="hidden" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

function bindDragEvents(dragEl, props, upload) {
  dragEl.addEventListener('dragover', (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    dragEl.classList.add('bg-gray-300');
  });
  dragEl.addEventListener('dragleave', (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    dragEl.classList.remove('bg-gray-300');
  });
  dragEl.addEventListener('drop', (evt) => {
    evt.preventDefault();
    dragEl.classList.remove('bg-gray-300');
    const files = evt.target.files || evt.dataTransfer.files;
    for (const file of files) {
      if (!file.type) {
        return;
      }
      if (props.accept && !props.accept.includes(file.type)) {
        return;
      }
      if (upload) {
        upload(file);
      }
    }
  });
}

export default {
  props: [
    'disabled',
    'accept',
    'multiple'
  ],
  emits: [
    'upload'
  ],
  setup(props, { emit }) {

    const fileInput = ref(null);
    const dropZone = ref(null);

    onMounted(() => {
      if (dropZone.value) {
        bindDragEvents(dropZone.value, props, file => {
          if (props.disabled) {
            return;
          }
          emit('upload', file);
        });
      }
    });

    onMounted(() => {
      if (fileInput.value) {
        fileInput.value.addEventListener('change', evt => {
          for(const file of fileInput.value.files) {
            if (props.disabled) {
              return;
            }
            emit('upload', file);
          }
        });
      }
    });

    return {
      fileInput,
      dropZone,
      disabled: computed(() => props.disabled),
      accept: computed(() => props.accept),
      multiple: computed(() => props.multiple)
    }
  }
}
</script>
