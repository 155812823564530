import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client/core';
import axios from 'axios';

export function getApolloClient(uri, headers = {}) {
  if (!uri) {
    throw new Error('Apollo Client URI Missing.');
  }
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createHttpLink({
      uri,
      credentials: 'Authorization' in headers ? null : 'include',
      headers: headers ? headers : {},
    }),
  });
}

export async function getClaims(client, query) {
  if (query.isAdmin) {
    const result = await client.query({
      fetchPolicy: 'network-only',
      query: gql`
        query Claims($tenant: ID, $after: ID, $type: String, $demo: Boolean, $tpaClaimId: String, $incidentDate: String, $vehicleId: String) {
          claims(tenant: $tenant, after: $after, type: $type, demo: $demo, tpaClaimId: $tpaClaimId, incidentDate: $incidentDate, vehicleId: $vehicleId) {
            id
            tenant
            businessLegalName
            documentId
            tpaClaimId
            created
            modified
            incidentDate
            status
            vehicle
            driver
            eligiblePolicies
            insuredVehicleVin
            isTotalLoss
          }
        }
      `,
      variables: {
        tenant: query.tenantId || '*',
        type: query.type,
        after: query.after,
        demo: query.demo,
        tpaClaimId: query.tpaClaimId,
        incidentDate: query.incidentDate,
        vehicleId: query.vehicleId
      }
    });
    return result.data?.claims || null;
  } else {
    const result = await client.query({
      fetchPolicy: 'network-only',
      query: gql`
      query Claims($tenant: ID, $after: ID, $type: String, $demo: Boolean, $tpaClaimId: String, $incidentDate: String, $vehicleId: String) {
        claims(tenant: $tenant, after: $after, type: $type, demo: $demo, tpaClaimId: $tpaClaimId, incidentDate: $incidentDate, vehicleId: $vehicleId) {
          id
          tenant
          businessLegalName
          documentId
          tpaClaimId
          created
          modified
          incidentDate
          status
          vehicle
          driver
          isFnolSubmittedToTpa
          insuredVehicleVin
          isTotalLoss
        }
      }
      `,
      variables: {
        tenant: query.tenantId || '*',
        type: query.type,
        after: query.after,
        demo: query.demo,
        tpaClaimId: query.tpaClaimId,
        incidentDate: query.incidentDate,
        vehicleId: query.vehicleId
      }
    });
    return result.data?.claims || null;
  }
}
export async function getThirdPartyClaims(client, query) {
  if (query.isAdmin) {
    const result = await client.query({
      fetchPolicy: 'network-only',
      query: gql`
        query ThirdPartyClaims($tenant: ID, $after: ID, $type: String, $demo: Boolean, $tpaClaimId: String) {
          thirdPartyClaims(tenant: $tenant, after: $after, type: $type, demo: $demo, tpaClaimId: $tpaClaimId) {
            id
            tenantId
            businessLegalName
            documentId
            tpaClaimId
            created
            modified
            incidentDate
            status
            vehicleId
            eligiblePolicies
            insuredVehicleVin
            isTotalLoss
          }
        }
      `,
      variables: {
        tenant: query.tenantId || '*',
        type: query.type,
        after: query.after,
        demo: query.demo,
        tpaClaimId: query.tpaClaimId,
      }
    });
    return result.data?.thirdPartyClaims || null;
  } else {
    const result = await client.query({
      query: gql`
      query ThirdPartyClaims($tenant: ID, $after: ID, $type: String, $demo: Boolean, $tpaClaimId: String) {
        thirdPartyClaims(tenant: $tenant, after: $after, type: $type, demo: $demo, tpaClaimId: $tpaClaimId) {
          id
          tenantId
          businessLegalName
          documentId
          tpaClaimId
          created
          modified
          incidentDate
          status
          vehicleId
          insuredVehicleVin
          isTotalLoss
        }
      }
      `,
      variables: {
        tenant: query.tenantId || '*',
        type: query.type,
        after: query.after,
        demo: query.demo,
        tpaClaimId: query.tpaClaimId,
      }
    });
    return result.data?.thirdPartyClaims || null;
  }
}

export async function getClaimById(client, claimId, tenantId = null) {
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query GetClaimById($claimId: ID!, $tenantId: ID) {
        claim(id: $claimId, tenant: $tenantId) {
          id
          documentId
          created
          modified
          adjuster
          adjusterCode
          uniqueLocationCode
          description
          witness
          thirdParty
          injuries
          passengers
          police
          rental
          windshield
          naturalDisaster
          incidentDate
          incidentTime
          locationAddressLineOne
          locationAddressLineTwo
          locationCity
          locationZipcode
          locationState
          contactFirstName
          contactLastName
          contactEmail
          contactPhone
          driverRelation
          driverFirstName
          driverLastName
          driverAddressLineOne
          driverAddressLineTwo
          driverCity
          driverState
          driverZipcode
          driverPhone
          driverLicenseNumber
          driverLicenseState
          tpaClaimId
          status
          vehicle
          driver
          isFnolSubmittedToTpa
          isTxtNotificationsOptedIn
          txtNotificationPhoneNum
          isTotalLoss
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });
  return result.data?.claim || null;
}
export async function getThirdPartyClaimById(client, claimId, tenantId = null) {
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query GetThirdPartyClaimById($claimId: ID!, $tenantId: ID) {
        thirdPartyClaim(id: $claimId, tenant: $tenantId) {
          id
          tenantId
          vehicleId
          adjusterId
          tpaClaimId
          documentId
          adjusterCode
          businessLegalName
          status
          reportedBy
          representativeName
          representativeContactName
          representativeContactEmail
          representativeContactPhone
          representativeAddressLineOne
          representativeAddressLineTwo
          representativeCity
          representativeState
          representativeZipcode
          claimantFirstName
          claimantLastName
          claimantEmail
          claimantPhone
          claimantAddressLineOne
          claimantAddressLineTwo
          claimantCity
          claimantState
          claimantZipcode
          claimantDriverLicenseNumber
          claimantDriverLicenseState
          policyNumber
          insuranceCarrierName
          contactPhone
          contactEmail
          incidentDate
          incidentTime
          incidentLocationAddressLineOne
          incidentLocationAddressLineTwo
          incidentLocationCity
          incidentLocationState
          incidentLocationZipcode
          eligiblePolicies
          description
          created
          modified
          modifiedBy
          claimAccountType
          claimDemoAccount
          isTotalLoss
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });
  return result.data?.thirdPartyClaim || null;
}

export async function saveClaim(client, claim, tenantId = null) {
  const result = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $claimId: ID,
        $tenantId: ID,
        $adjuster: ID,
        $adjusterCode: String,
        $comment: String,
        $incidentDate: String,
        $incidentTime: String,
        $locationAddressLineOne: String,
        $locationAddressLineTwo: String,
        $locationCity: String,
        $locationZipcode: String,
        $locationState: String,
        $contactFirstName: String,
        $contactLastName: String,
        $contactEmail: String,
        $contactPhone: String,
        $driverRelation: String,
        $driverFirstName: String,
        $driverLastName: String,
        $driverAddressLineOne: String,
        $driverAddressLineTwo: String,
        $driverCity: String,
        $driverState: String,
        $driverZipcode: String,
        $driverPhone: String,
        $driverLicenseNumber: String,
        $driverLicenseState: String,
        $injuries: String,
        $thirdParty: String,
        $police: Boolean,
        $passengers: Boolean,
        $rental: Boolean,
        $windshield: Boolean,
        $naturalDisaster: Boolean,
        $vehicle: String,
        $driver: String,
        $description: String,
        $status: String,
        $tpaClaimId: String
        $isTxtNotificationsOptedIn: Boolean
        $txtNotificationPhoneNum: String
        $isTotalLoss: Boolean
        $started: String
      ) {
        claim(
          id: $claimId,
          tenantId: $tenantId,
          adjuster: $adjuster,
          adjusterCode: $adjusterCode,
          comment: $comment,
          incidentDate: $incidentDate,
          incidentTime: $incidentTime,
          locationAddressLineOne: $locationAddressLineOne,
          locationAddressLineTwo: $locationAddressLineTwo,
          locationCity: $locationCity,
          locationZipcode: $locationZipcode,
          locationState: $locationState,
          contactFirstName: $contactFirstName,
          contactLastName: $contactLastName,
          contactEmail: $contactEmail,
          contactPhone: $contactPhone,
          driverRelation: $driverRelation,
          driverFirstName: $driverFirstName,
          driverLastName: $driverLastName,
          driverAddressLineOne: $driverAddressLineOne,
          driverAddressLineTwo: $driverAddressLineTwo,
          driverCity: $driverCity,
          driverState: $driverState,
          driverZipcode: $driverZipcode,
          driverPhone: $driverPhone,
          driverLicenseNumber: $driverLicenseNumber,
          driverLicenseState: $driverLicenseState,
          vehicle: $vehicle,
          driver: $driver,
          injuries: $injuries,
          thirdParty: $thirdParty,
          police: $police,
          passengers: $passengers,
          rental: $rental,
          windshield: $windshield,
          naturalDisaster: $naturalDisaster,
          description: $description,
          status: $status,
          tpaClaimId: $tpaClaimId
          isTxtNotificationsOptedIn: $isTxtNotificationsOptedIn
          txtNotificationPhoneNum: $txtNotificationPhoneNum
          isTotalLoss: $isTotalLoss
          started: $started
       ) {
          id
          documentId
          adjuster
          adjusterCode
          uniqueLocationCode
          created
          modified
          description
          witness
          thirdParty
          injuries
          passengers
          police
          rental
          windshield
          naturalDisaster
          incidentDate
          incidentTime
          locationAddressLineOne
          locationAddressLineTwo
          locationCity
          locationZipcode
          locationState
          contactFirstName
          contactLastName
          contactEmail
          contactPhone
          driverRelation
          driverFirstName
          driverLastName
          driverAddressLineOne
          driverAddressLineTwo
          driverCity
          driverState
          driverZipcode
          driverPhone
          driverLicenseNumber
          driverLicenseState
          tpaClaimId
          status
          vehicle
          driver
          isFnolSubmittedToTpa
          isTxtNotificationsOptedIn
          txtNotificationPhoneNum
          isTotalLoss
        }
      }
    `,
    variables: {
      claimId: claim.id,
      tenantId: claim.tenantId,
      adjuster: claim.adjuster,
      adjusterCode: claim.adjusterCode,
      comment: claim.comment,
      incidentDate: claim.incidentDate,
      incidentTime: claim.incidentTime,
      locationAddressLineOne: claim.locationAddressLineOne,
      locationAddressLineTwo: claim.locationAddressLineTwo,
      locationCity: claim.locationCity,
      locationZipcode: claim.locationZipcode,
      locationState: claim.locationState,
      contactFirstName: claim.contactFirstName,
      contactLastName: claim.contactLastName,
      contactEmail: claim.contactEmail,
      contactPhone: claim.contactPhone,
      driverRelation: claim.driverRelation,
      driverFirstName: claim.driverFirstName,
      driverLastName: claim.driverLastName,
      driverAddressLineOne: claim.driverAddressLineOne,
      driverAddressLineTwo: claim.driverAddressLineTwo,
      driverCity: claim.driverCity,
      driverState: claim.driverState,
      driverZipcode: claim.driverZipcode,
      driverPhone: claim.driverPhone,
      driverLicenseNumber: claim.driverLicenseNumber,
      driverLicenseState: claim.driverLicenseState,
      vehicle: claim.vehicle,
      driver: claim.driver,
      description: claim.description,
      thirdParty: claim.thirdParty,
      injuries: claim.injuries,
      police: claim.police,
      passengers: claim.passengers,
      rental: claim.rental,
      windshield: claim.windshield,
      naturalDisaster: claim.isNaturalDisaster,
      status: claim.status,
      tpaClaimId: claim.tpaClaimId,
      isTxtNotificationsOptedIn: claim.isTxtNotificationsOptedIn,
      txtNotificationPhoneNum: claim.txtNotificationPhoneNum,
      isTotalLoss: claim.isTotalLoss,
      started: claim.started
    }
  });
  return result.data?.claim || null;
}
export async function saveThirdPartyClaim(client, claim, tenantId = null) {
  const result = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $claimId: ID
        $tenantId: ID
        $vehicleId: ID
        $adjusterId: ID
        $adjusterCode: String
        $status: String
        $tpaClaimId: String
        $representativeName: String
        $representativeContactName: String
        $representativeContactEmail: String
        $representativeContactPhone: String
        $representativeAddressLineOne: String
        $representativeAddressLineTwo: String
        $representativeCity: String
        $representativeState: String
        $representativeZipcode: String
        $claimantFirstName: String
        $claimantLastName: String
        $claimantEmail: String
        $claimantPhone: String
        $claimantAddressLineOne: String
        $claimantAddressLineTwo: String
        $claimantCity: String
        $claimantState: String
        $claimantZipcode: String
        $claimantDriverLicenseNumber: String
        $claimantDriverLicenseState: String
        $policyNumber: String
        $insuranceCarrierName: String
        $contactPhone: String
        $contactEmail: String
        $incidentDate: String
        $incidentTime: String
        $incidentLocationAddressLineOne: String
        $incidentLocationAddressLineTwo: String
        $incidentLocationCity: String
        $incidentLocationState: String
        $incidentLocationZipcode: String
        $description: String
        $comment: String
        $isTotalLoss: Boolean
      ) {
        thirdPartyClaim(
          id: $claimId
          tenantId: $tenantId
          vehicleId: $vehicleId
          adjusterId: $adjusterId
          status: $status
          tpaClaimId: $tpaClaimId
          adjusterCode: $adjusterCode
          representativeName: $representativeName
          representativeContactName: $representativeContactName
          representativeContactEmail: $representativeContactEmail
          representativeContactPhone: $representativeContactPhone
          representativeAddressLineOne: $representativeAddressLineOne
          representativeAddressLineTwo: $representativeAddressLineTwo
          representativeCity: $representativeCity
          representativeState: $representativeState
          representativeZipcode: $representativeZipcode
          claimantFirstName: $claimantFirstName
          claimantLastName: $claimantLastName
          claimantEmail: $claimantEmail
          claimantPhone: $claimantPhone
          claimantAddressLineOne: $claimantAddressLineOne
          claimantAddressLineTwo: $claimantAddressLineTwo
          claimantCity: $claimantCity
          claimantState: $claimantState
          claimantZipcode: $claimantZipcode
          claimantDriverLicenseNumber: $claimantDriverLicenseNumber
          claimantDriverLicenseState: $claimantDriverLicenseState
          policyNumber: $policyNumber
          insuranceCarrierName: $insuranceCarrierName
          contactPhone: $contactPhone
          contactEmail: $contactEmail
          incidentDate: $incidentDate
          incidentTime: $incidentTime
          incidentLocationAddressLineOne: $incidentLocationAddressLineOne
          incidentLocationAddressLineTwo: $incidentLocationAddressLineTwo
          incidentLocationCity: $incidentLocationCity
          incidentLocationState: $incidentLocationState
          incidentLocationZipcode: $incidentLocationZipcode
          description: $description
          comment: $comment
          isTotalLoss: $isTotalLoss
       ) {
          id
          tenantId
          vehicleId
          adjusterId
          tpaClaimId
          documentId
          businessLegalName
          status
          adjusterCode
          reportedBy
          representativeName
          representativeContactName
          representativeContactEmail
          representativeContactPhone
          representativeAddressLineOne
          representativeAddressLineTwo
          representativeCity
          representativeState
          representativeZipcode
          claimantFirstName
          claimantLastName
          claimantEmail
          claimantPhone
          claimantAddressLineOne
          claimantAddressLineTwo
          claimantCity
          claimantState
          claimantZipcode
          claimantDriverLicenseNumber
          claimantDriverLicenseState
          policyNumber
          insuranceCarrierName
          contactPhone
          contactEmail
          incidentDate
          incidentTime
          incidentLocationAddressLineOne
          incidentLocationAddressLineTwo
          incidentLocationCity
          incidentLocationState
          incidentLocationZipcode
          eligiblePolicies
          description
          created
          modified
          modifiedBy
          claimAccountType
          claimDemoAccount
          isTotalLoss
        }
      }
    `,
    variables: {
      claimId: claim.id,
      tenantId: claim.tenantId,
      vehicleId: claim.vehicleId,
      adjusterId: claim.adjuster,
      tpaClaimId: claim.tpaClaimId,
      documentId: claim.documentId,
      adjusterCode: claim.adjusterCode,
      businessLegalName: claim.businessLegalName,
      status: claim.status,
      representativeName: claim.representativeName,
      representativeContactName: claim.representativeContactName,
      representativeContactEmail: claim.representativeContactEmail,
      representativeContactPhone: claim.representativeContactPhone,
      representativeAddressLineOne: claim.representativeAddressLineOne,
      representativeAddressLineTwo: claim.representativeAddressLineTwo,
      representativeCity: claim.representativeCity,
      representativeState: claim.representativeState,
      representativeZipcode: claim.representativeZipcode,
      claimantFirstName: claim.claimantFirstName,
      claimantLastName: claim.claimantLastName,
      claimantEmail: claim.claimantEmail,
      claimantPhone: claim.claimantPhone,
      claimantAddressLineOne: claim.claimantAddressLineOne,
      claimantAddressLineTwo: claim.claimantAddressLineTwo,
      claimantCity: claim.claimantCity,
      claimantState: claim.claimantState,
      claimantZipcode: claim.claimantZipcode,
      claimantDriverLicenseNumber: claim.claimantDriverLicenseNumber,
      claimantDriverLicenseState: claim.claimantDriverLicenseState,
      policyNumber: claim.policyNumber,
      insuranceCarrierName: claim.insuranceCarrierName,
      contactPhone: claim.contactPhone,
      contactEmail: claim.contactEmail,
      incidentDate: claim.incidentDate,
      incidentTime: claim.incidentTime,
      incidentLocationAddressLineOne: claim.locationAddressLineOne,
      incidentLocationAddressLineTwo: claim.locationAddressLineTwo,
      incidentLocationCity: claim.locationCity,
      incidentLocationState: claim.locationState,
      incidentLocationZipcode: claim.locationZipcode,
      eligiblePolicies: claim.eligiblePolicies,
      description: claim.description,
      created: claim.created,
      modified: claim.modified,
      modifiedBy: claim.modifiedBy,
      comment: claim.comment,
      isTotalLoss: claim.isTotalLoss
    }
  });

  return await convertThirdPartyClaimObjectToClaimObject(result.data?.thirdPartyClaim || null);
}

export async function getThirdPartiesForClaim(client, claimId, tenantId = null) {
  const result = await client.mutate({
    mutation: gql`
      query ThirdParties($claimId: ID!, $tenantId: ID) {
        thirdParties(claimId: $claimId, tenantId: $tenantId) {
          id
          type
          firstName
          lastName
          phone
          email
          injured
          policy
          carrier
          policeDepartment
          policeCaseNumber
          licenseNumber
          licenseAddressLineOne
          licenseAddressLineTwo
          licenseCity
          licenseState
          licenseZipcode
          licenseCountry
          locationAddressLineOne
          locationAddressLineTwo
          locationCity
          locationState
          locationZipcode
          locationCountry
          vehicleVin
          description
          archive
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });
  return result.data?.thirdParties || null;
}

export async function saveThirdPartyForClaim(client, claimId, thirdParty) {
  const result = await client.mutate({
    mutation: gql`
      mutation ThirdParty(
        $claimId: ID!,
        $tenant: ID,
        $thirdPartyId: ID,
        $type: String,
        $lastName: String,
        $firstName: String,
        $phone: String,
        $injured: Boolean,
        $email: String,
        $policy: String,
        $carrier: String,
        $policeDepartment: String,
        $policeCaseNumber: String,
        $licenseNumber: String,
        $licenseAddressLineOne: String,
        $licenseAddressLineTwo: String,
        $licenseCity: String,
        $licenseState: String,
        $licenseZipcode: String,
        $licenseCountry: String,
        $locationAddressLineOne: String,
        $locationAddressLineTwo: String,
        $locationCity: String,
        $locationState: String,
        $locationZipcode: String,
        $locationCountry: String,
        $description: String,
        $vehicleVin: String,
        $archive: Boolean,
      ) {
        thirdParty(
          claimId: $claimId,
          tenant: $tenant,
          id: $thirdPartyId,
          type: $type,
          lastName: $lastName,
          firstName: $firstName,
          phone: $phone,
          injured: $injured,
          email: $email,
          carrier: $carrier,
          policeDepartment: $policeDepartment,
          policy: $policy,
          policeCaseNumber: $policeCaseNumber,
          licenseNumber: $licenseNumber,
          licenseAddressLineOne: $licenseAddressLineOne,
          licenseAddressLineTwo: $licenseAddressLineTwo,
          licenseCity: $licenseCity,
          licenseState: $licenseState,
          licenseZipcode: $licenseZipcode,
          licenseCountry: $licenseCountry,
          locationAddressLineOne: $locationAddressLineOne,
          locationAddressLineTwo: $locationAddressLineTwo,
          locationCity: $locationCity,
          locationState: $locationState,
          locationZipcode: $locationZipcode,
          locationCountry: $locationCountry,
          description: $description,
          vehicleVin: $vehicleVin,
          archive: $archive,
        ) {
          id
          type
          firstName
          lastName
          phone
          email
          injured
          policy
          carrier
          policeDepartment
          policeCaseNumber
          licenseNumber
          licenseAddressLineOne
          licenseAddressLineTwo
          licenseCity
          licenseState
          licenseZipcode
          licenseCountry
          locationAddressLineOne
          locationAddressLineTwo
          locationCity
          locationState
          locationZipcode
          locationCountry
          vehicleVin
          description
          archive
        }
      }
    `,
    variables: {
      claimId,
      tenant: thirdParty.tenant || null,
      thirdPartyId: thirdParty.id || null,
      type: thirdParty.type || null,
      lastName: thirdParty.lastName || '',
      firstName: thirdParty.firstName || '',
      phone: thirdParty.phone || '',
      injured: thirdParty.injured || null,
      email: thirdParty.email || '',
      carrier: thirdParty.carrier || '',
      policeDepartment: thirdParty.policeDepartment || '',
      policy: thirdParty.policy || '',
      policeCaseNumber: thirdParty.policeCaseNumber || '',
      licenseNumber: thirdParty.licenseNumber || '',
      licenseAddressLineOne: thirdParty.licenseAddressLineOne || '',
      licenseAddressLineTwo: thirdParty.licenseAddressLineTwo || '',
      licenseCity: thirdParty.licenseCity || '',
      licenseState: thirdParty.licenseState || '',
      licenseZipcode: thirdParty.licenseZipcode || '',
      licenseCountry: thirdParty.licenseCountry || '',
      locationAddressLineOne: thirdParty.locationAddressLineOne || '',
      locationAddressLineTwo: thirdParty.locationAddressLineTwo || '',
      locationCity: thirdParty.locationCity || '',
      locationState: thirdParty.locationState || '',
      locationZipcode: thirdParty.locationZipcode || '',
      locationCountry: thirdParty.locationCountry || '',
      description: thirdParty.description || '',
      vehicleVin: thirdParty.vehicleVin || '',
      archive: thirdParty.archive || false,
    }
  });
  return result.data?.thirdParty || null;
}

export async function getChangelogForClaim(client, claimId, tenantId = null) {
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query Changelog($claimId: ID!, $tenantId: ID) {
        changelog(claimId: $claimId, tenantId: $tenantId) {
          documentId
          actor
          description
          effective
          entityId
          eventType
          fieldName
          newValue
          oldValue
          timestamp
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });
  return result.data?.changelog || null;
}

export async function getChangelogForThirdPartyClaim(client, claimId, tenantId = null) {
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query Changelog($claimId: ID!, $tenantId: ID) {
        thirdPartyClaimChangelog(claimId: $claimId, tenantId: $tenantId) {
          documentId
          actor
          description
          effective
          entityId
          eventType
          fieldName
          newValue
          oldValue
          timestamp
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });
  return result.data?.thirdPartyClaimChangelog || null;
}

export async function getAttachmentsForClaim(client, claimId, tenantId = null) {
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query Attachment($claimId: ID!, $tenantId: ID) {
        attachments(claimId: $claimId, tenantId: $tenantId) {
          id
          name
          type
          description
          object
          url
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });
  return result.data?.attachments || null;
}

export async function getAttachmentsForThirdPartyClaim(client, claimId, tenantId = null) {
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query Attachment($claimId: ID!, $tenantId: ID) {
        thirdPartyClaimAttachments(claimId: $claimId, tenantId: $tenantId) {
          id
          name
          type
          description
          object
          url
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });
  return result.data?.thirdPartyClaimAttachments || null;
}

export async function saveAttachmentForClaim(client, claimId, attachment, tenantId = null) {
  const result = await client.mutate({
    mutation: gql`
      mutation Attachment(
        $claimId: ID!,
        $tenantId: ID,
        $attachmentId: ID
        $name: String,
        $type: String,
        $description: String,
      ) {
        attachment(
          claimId: $claimId,
          tenantId: $tenantId,
          id: $attachmentId
          name: $name,
          type: $type,
          description: $description,
        ) {
          id
          name
          type
          description
          object
          url
      }
    }
    `,
    variables: {
      ...attachment,
      attachmentId: attachment.object ? attachment.id : null,
      claimId,
      tenantId,
    }
  });
  if (attachment.file) {
    if (result.data.attachment.url.includes('storage.googleapis.com')) {
      await axios.put(
        result.data.attachment.url,
        attachment.file,
      );
    } else {
      await axios.post(
        result.data.attachment.url,
        attachment.file,
      );
    }
  }
  return result.data.attachment || null;
}

export async function saveAttachmentForThirdPartyClaim(client, claimId, attachment, tenantId = null) {
  const result = await client.mutate({
    mutation: gql`
      mutation Attachment(
        $claimId: ID!,
        $tenantId: ID,
        $attachmentId: ID
        $name: String,
        $type: String,
        $description: String,
      ) {
        thirdPartyClaimAttachment(
          claimId: $claimId,
          tenantId: $tenantId,
          id: $attachmentId
          name: $name,
          type: $type,
          description: $description,
        ) {
          id
          name
          type
          description
          object
          url
      }
    }
    `,
    variables: {
      ...attachment,
      attachmentId: attachment.object ? attachment.id : null,
      claimId,
      tenantId,
    }
  });
  if (attachment.file) {
    if (result.data.thirdPartyClaimAttachment.url.includes('storage.googleapis.com')) {
      await axios.put(
        result.data.thirdPartyClaimAttachment.url,
        attachment.file,
      );
    } else {
      await axios.post(
        result.data.thirdPartyClaimAttachment.url,
        attachment.file,
      );
    }
  }
  return result.data.thirdPartyClaimAttachment || null;
}

export async function getClaimExport(client, claimId, tenantId = null) {
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query Export(
        $claim: ID!,
        $tenant: ID,
      ) {
        export(
          claim: $claim,
          tenant: $tenant
        ) {
          url
        }
      }
    `,
    variables: {
      claim: claimId,
      tenant: tenantId,
    }
  });
  return result.data.export || null;
}

export async function sendFnolToTpa(client, claimId, tenantId = null) {
  const result = await client.mutate({
    mutation: gql`
    mutation SendFnolToTpa(
        $claimId: ID!,
        $tenantId: ID,
      ) {
        sendFnolToTpa(
          claimId: $claimId,
          tenantId: $tenantId
        ) {
          success,
          message
        }
      }
    `,
    variables: {
      claimId: claimId,
      tenantId: tenantId,
    }
  });
  return result.data.sendFnolToTpa || null;
}

export async function getPolicyTypesForClaim(client, claimId, tenantId){
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query PolicyTypes($claimId: ID!, $tenantId: ID) {
        policyTypes(claimId: $claimId, tenantId: $tenantId) {
          documentId
          name
          number
          broker
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });

  return result.data?.policyTypes || null;
}

export async function getPolicyTypesForThirdPartyClaim(client, claimId, tenantId){
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query PolicyTypes($claimId: ID!, $tenantId: ID) {
        thirdPartyClaimPolicyTypes(claimId: $claimId, tenantId: $tenantId) {
          documentId
          name
          number
          broker
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
    }
  });

  return result.data?.thirdPartyClaimPolicyTypes || null;
}

export async function validatePhoneNumber(client, phoneNumber){
  const result = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query ValidatePhoneNumber($phoneNumber: String) {
        validatePhoneNumber(phoneNumber: $phoneNumber) {
          isValid
          isMobile
        }
      }
    `,
    variables: {
      phoneNumber
    }
  });
  return result.data?.validatePhoneNumber || null;
}
export async function convertThirdPartyClaimObjectToClaimObject(thirdPartyClaim){
  if(thirdPartyClaim)
  {
    var convertedClaim =  {};
    Object.assign(convertedClaim, thirdPartyClaim);
    convertedClaim.tenant = thirdPartyClaim.tenantId;
    convertedClaim.adjuster = thirdPartyClaim.adjusterId;
    convertedClaim.locationAddressLineOne = thirdPartyClaim.incidentLocationAddressLineOne;
    convertedClaim.locationAddressLineTwo = thirdPartyClaim.incidentLocationAddressLineTwo;
    convertedClaim.locationCity = thirdPartyClaim.incidentLocationCity;
    convertedClaim.locationState = thirdPartyClaim.incidentLocationState;
    convertedClaim.locationZipcode = thirdPartyClaim.incidentLocationZipcode;
    delete convertedClaim.tenantId
    delete convertedClaim.adjusterId;
    delete convertedClaim.incidentLocationAddressLineOne;
    delete convertedClaim.incidentLocationAddressLineTwo;
    delete convertedClaim.incidentLocationCity;
    delete convertedClaim.incidentLocationState;
    delete convertedClaim.incidentLocationZipcode;
    return convertedClaim;
  }
  else
    return null;
}
