<template>
  <div class="max-w-4xl">
    <div v-if="!isAdmin">
      <div class="my-4 rounded-lg bg-green-50 p-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="hidden h-16 w-16 text-lula-success md:block"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="
              M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1
              0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414
              1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd" />
        </svg>
        <div class="my-4">
          <h3 class="text-2xl font-bold text-gray-700">
            Claim successfully filed.
          </h3>
          <p>Don't worry we're on it!</p>
          <p class="text-lg text-gray-600 mt-4" v-if="account?.contactEmail">
            Meanwhile we've sent a confirmation message to {{ maskEmail(account.contactEmail) }}
          </p>
        </div>
      </div>
      <div class="bg-white rounded-md p-6 my-4" v-if="nextSteps.length > 0">
        <h3 class="text-2xl text-gray-800 font-bold mb-4">
          Next Steps
        </h3>
        <ol>
          <li class="list-disc mx-6 md:list-none md:mx-0" v-for="st in nextSteps" :key="st">
            <div class="flex items-center text-lg text-gray-600 my-4 md:my-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="hidden h-12 w-12 text-lula mr-4 md:block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
              <p>
                {{ st }}
              </p>
            </div>
          </li>
        </ol>
      </div>
    </div>
    <TransitionGroup name="tp-list">
      <div
        class="my-4"
        v-for="tp in thirdParties"
        :key="tp.id">
          <Transition>
            <div class="bg-white rounded-lg p-6 text-gray-800" v-if="currentEdit !== tp.id">
              <p class="font-bold text-2xl text-gray-700">
                Third Party Information
              </p>
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div
                  class="p-1"
                  :class="{ 'md:col-span-2': field.key === 'description' }"
                  v-for="field in getTpFields(tp)"
                  :key="field.key">
                    <span class="block font-bold text-gray-600">
                      {{ field.label }}
                    </span>
                    <span class="block" v-if="field.value === true">
                      Yes
                    </span>
                    <span class="block" v-else-if="field.value === false">
                      No
                    </span>
                    <span class="block" v-else>
                      {{ field.value }}
                    </span>
                </div>
              </div>
              <div v-if="!currentEdit && canEdit">
                <button @click="currentEdit = tp.id" class="
                  hidden bg-gray-200 text-gray-600 mt-4
                  hover:bg-gray-300 w-fit md:block">
                    Edit Third Party Information
                </button>
                <button @click="currentEdit = tp.id" class="
                  w-full bg-gray-200 text-gray-600
                  mt-4 hover:bg-gray-300 md:hidden">
                    Edit Third Party Information
                </button>
              </div>
            </div>
            <AddThirdParty
              :id="tp.id"
              @close="currentEdit = null"
              v-else />
          </Transition>
      </div>
    </TransitionGroup>
    <Transition v-if="canEdit">
      <AddThirdParty v-if="$store.state.addThirdPartyEnabled" />
      <div v-else>
        <button
          :class="{
            'opacity-0': !thirdParties,
            'opacity-100': thirdParties,
          }"
          class="
            transition-all duration-300 w-full flex
            items-center justify-center text-gray-600
            bg-gray-200 hover:bg-gray-300 md:hidden"
          @click="$store.commit('setItem', {
            key: 'addThirdPartyEnabled',
            value: true
          })">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          <span>
            Add an additional Third Party
          </span>
        </button>
        <button
          :class="{
            'opacity-0': !thirdParties,
            'opacity-100': thirdParties,
          }"
          class="
            transition-all duration-300 hidden
            items-center justify-center text-gray-600
            bg-gray-200 hover:bg-gray-300 md:flex"
          @click="$store.commit('setItem', {
            key: 'addThirdPartyEnabled',
            value: true
          })">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            <span>
              Add an additional Third Party
            </span>
        </button>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from 'vue';
import { useStore } from 'vuex';
import AddThirdParty from '@/ClaimForm/AddThirdParty.vue';

function maskEmail(email) {
  var maskedEmail = email.replace(/([^@.])/g, "*").split('');
  var previous  = "";
  for(let i=0;i<maskedEmail.length;i++){
    if (i<=1 || previous == "." || previous == "@"){
      maskedEmail[i] = email[i];
    }
    previous = email[i];
  }
  return maskedEmail.join('');
}

function formatLabel(label) {
  return label
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
}

export default {
  components: { AddThirdParty },
  setup() {

    const store = useStore();

    const mode = inject('mode') || 'cars';
    const isAdmin = mode.includes('admin_');
    const tenantId = inject('tenantId') || null;
    const account = inject('account') || null;
    const nextSteps = inject('nextSteps') || '';
    const currentEdit = ref(null);

    onMounted(async () => {
      if (store.state.claim?.id) {
        await store.dispatch(
          'getThirdPartiesForClaim',
          { claimId: store.state.claim.id, tenantId }
        );
      } else {
        store.commit({
          key: 'thirdParties',
          value: [],
        });
      }
    });

    return {
      isAdmin,
      account,
      currentEdit,
      canEdit: computed(() => {
        if (isAdmin) {
          return true;
        }
        if (store.state.claim?.id) {
          return store.state.claim.status === 'reported';
        }
        return true;
      }),
      maskEmail,
      nextSteps: nextSteps.split('|')
        .filter(st => st),
      thirdParties: computed(() => {
        if (!store.state.thirdParties) {
          return null;
        }
        return store.state.thirdParties
          .filter(tp => !tp.archive)
          .map(tp => {
            return {
              id: tp.id,
              firstName: tp.firstName,
              lastName: tp.lastName,
              phone: tp.phone,
              email: tp.email,
              injured: tp.injured,
              policy: tp.policy,
              carrier: tp.carrier,
              policeDepartment: tp.policeDepartment,
              policeCaseNumber: tp.policeCaseNumber,
              licenseNumber: tp.licenseNumber,
              thirdPartyAddress: tp.locationAddressLineOne ?
                `${tp.locationAddressLineOne}${tp.locationAddressLineTwo ? ` ${tp.locationAddressLineTwo}` : ''},
                ${tp.locationCity} ${tp.locationState} ${tp.locationZipcode}, ${tp.locationCountry}` : '',
              licenseAddress: tp.licenseAddressLineOne ?
                `${tp.licenseAddressLineOne}${tp.licenseAddressLineTwo ? ` ${tp.licenseAddressLineTwo}` : ''},
                ${tp.licenseCity} ${tp.licenseState} ${tp.licenseZipcode}, ${tp.licenseCountry}` : '',
              vehicle: tp.vehicleVin,
              description: tp.description,
            }
          });
      }),
      getTpFields(tp) {
        return Object.keys(tp)
          .map(field => {
            return {
              label: formatLabel(field),
              key: field,
              value: tp[field]
            }
          })
          .filter(tp => {
            if (tp.key === 'id') {
              return false;
            }
            return tp.value
          })
      }
    }
  }
}
</script>

<style scoped>
.v-enter-active {
  transition: opacity .25s ease;
}
.v-leave-active {
  display: none;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.tp-list-enter-active,
.tp-list-leave-active {
  transition: all 0.5s ease;
}
.tp-list-enter-from,
.tp-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
