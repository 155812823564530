<template>
  <div>
    <div
      class="my-2"
      v-for="tp, idx in thirdParties"
      :key="tp.id">
      <div
        :class="{
          'bg-gray-300': isSubmitting,
          'bg-gray-100': !isSubmitting
        }"
        class="
          p-4 rounded
          grid grid-cols-1 md:grid-cols-2">
        <div
          class="p-1"
          :class="{ 'md:col-span-2': field.key === 'description' }"
          v-for="field in getTpFields(tp)"
          :key="field.key">
            <span class="block font-bold text-gray-600">
              {{ field.label }}
            </span>
            <span class="block" v-if="field.value === true">
              Yes
            </span>
            <span class="block" v-else-if="field.value === false">
              No
            </span>
            <span class="block" v-else>
              {{ field.value }}
            </span>
        </div>
        <div class="mt-4 md:col-span-2" :class="{ 'opacity-0': !canEdit }">
          <span @click="removeThirdParty(tp.id)" class="
            bg-red-100 mr-2 text-red-500 px-4 py-2 rounded-full
            cursor-pointer hover:bg-red-200">
              Remove
          </span>
          <span @click="toggleEditThirdParty(tp.id)" class="
            bg-gray-200 text-gray-600 px-6 py-2 rounded-full
            cursor-pointer hover:bg-gray-300">
              Edit
          </span>
        </div>
      </div>
    </div>
    <div class="border rounded-lg" v-if="editThirdParty['__isOpen']">
      <AddThirdPartyInline @close="toggleEditThirdParty" />
    </div>
    <div class="mt-2" v-else>
      <button
        class="
          flex items-center text-gray-600 hover:bg-gray-300"
        :class="{
          'bg-gray-100': canEdit,
          'bg-gray-300': !canEdit
        }"
        :disabled="!canEdit"
        type="button"
        @click="toggleEditThirdParty">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 mr-2">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v12m6-6H6" />
          </svg>
            Add Third Party
      </button>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import AddThirdPartyInline from '@/ClaimForm/AddThirdPartyInline.vue';

function formatLabel(label) {
  return label
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
}

export default {
  components: { AddThirdPartyInline },
  setup() {

    const store = useStore();

    const mode = inject('mode') || 'cars';
    const isAdmin = mode.includes('admin_');
    const tenantId = inject('tenantId');
    const editThirdParty = ref({ '__isOpen': false });

    onMounted(async () => {
      if (store.state.claim?.id) {
        await store.dispatch(
          'getThirdPartiesForClaim',
          { claimId: store.state.claim.id, tenantId }
        );
      } else {
        store.commit('setItem', {
          key: 'thirdParties',
          value: [],
        });
      }
    });

    return {
      editThirdParty,
      isSubmitting: computed(() =>
        store.state.isSubmittingForm
      ),
      canEdit: computed(() => {
        if (store.state.isSubmittingForm) {
          return false;
        }
        if (isAdmin) {
          return true;
        }
        if (store.state.claim?.id) {
          return store.state.claim.status === 'reported';
        }
        return true;
      }),
      toggleEditThirdParty(editThirdPartyId = null) {
        editThirdParty.value['__isOpen'] =
          !editThirdParty.value['__isOpen']
        store.commit('setItem', {
          key: 'editThirdPartyId',
          value: editThirdPartyId
        });
        setTimeout(() => {
          if (editThirdPartyId) {
            const tpHeader =
              document.getElementById('edit-third-party-header');
            if (tpHeader) {
              tpHeader.scrollIntoView();
            }
          }
        }, 0);
      },
      thirdParties: computed(() => {
        if (!store.state.thirdParties) {
          return [];
        }
        return store.state.thirdParties
          .filter(tp => !tp.archive)
          .filter(({ id }) =>
            id !== store.state.editThirdPartyId
          )
          .map(tp => {
            return {
              id: tp.id,
              firstName: tp.firstName,
              lastName: tp.lastName,
              phone: tp.phone,
              email: tp.email,
              injured: tp.injured,
              policy: tp.policy,
              carrier: tp.carrier,
              policeDepartment: tp.policeDepartment,
              policeCaseNumber: tp.policeCaseNumber,
              licenseNumber: tp.licenseNumber,
              thirdPartyAddress: tp.locationAddressLineOne ?
                `${tp.locationAddressLineOne}${tp.locationAddressLineTwo ? ` ${tp.locationAddressLineTwo}` : ''},
                ${tp.locationCity} ${tp.locationState} ${tp.locationZipcode}, ${tp.locationCountry}` : '',
              licenseAddress: tp.licenseAddressLineOne ?
                `${tp.licenseAddressLineOne}${tp.licenseAddressLineTwo ? ` ${tp.licenseAddressLineTwo}` : ''},
                ${tp.licenseCity} ${tp.licenseState} ${tp.licenseZipcode}, ${tp.licenseCountry}` : '',
              vehicle: tp.vehicleVin,
              description: tp.description,
              '__createdDate': moment.utc(tp.created).toDate()
            }
          })
          .sort((a, b) =>
            a['__createdDate'] < b['__createdDate'] ? -1 : 1
          )
      }),
      getTpFields(tp) {
        return Object.keys(tp)
          .filter(tp => !tp.includes('__'))
          .map(field => {
            return {
              label: formatLabel(field),
              key: field,
              value: tp[field]
            }
          })
          .filter(tp => {
            if (tp.key === 'id') {
              return false;
            }
            return tp.value
          })
      },
      removeThirdParty(thirdPartyId) {
        if (!confirm('Would you like to remove this third party?')) {
          return;
        }
        store.commit('setItem', {
          key: 'thirdParties',
          value: store.state.thirdParties
            .map(tp =>
              ({
                ...tp,
                archive: tp.id === thirdPartyId ?
                  true : tp.archive
              })
            )
        });
      }
    };
  }
}
</script>
