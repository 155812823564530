<template>
  <div>
    <div class="flex text-gray-600 md:border-b">
      <div
        :class="{
          'text-gray-800': step.active,
          'cursor-pointer hover:text-gray-800 ': !step.disabled,
          'bg-lula-light': step.admin && step.active,
          'hover:bg-lula-light': step.admin && !step.disabled
        }"
        class="hidden transition relative p-4 text-gray-600 rounded-t-lg md:block"
        v-for="step in steps"
        :key="step.key"
        @click="selectStep(step)">
        {{ isAdmin ? '' : `${step.index}.` }} {{ step.label }}
        <div
          :class="{
            'opacity-100': step.active
          }"
          class="
            transition-all opacity-0 absolute bottom-0
            left-0 rounded-t bg-lula-gradient h-1 w-full"/>
      </div>
      <div
        :class="{
          'bg-lula-gradient text-white': step.active,
          'bg-white text-gray-800': !step.active,
          'bg-gray-200': step.disabled,
          'cursor-pointer': !step.disabled,
        }"
        class="
          relative py-2 px-4 transition-all
          rounded m-1 bg-white text-center text-lg md:hidden"
        v-for="step in steps"
        :key="step.key"
        @click="selectStep(step)">
          {{ step.admin ? '*' : step.index }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {

    const store = useStore();
    const mode = inject('mode') || 'cars';
    const isAdmin = mode.includes('admin');

    return {
      isAdmin,
      steps: computed(() => {
        const steps = store.state.steps;
        return Object.keys(steps || {})
          .filter(step =>
            isAdmin ? true : steps[step].admin === false
          )
          .filter(step =>
            steps[step].hidden !== true
          )
          .map((step, idx) => {
            const mappedStep = {
              key: step,
              ...steps[step],
            }
            if (!store.state.claim?.id && idx > 0) {
              mappedStep.disabled = true;
            }
            return mappedStep;
          })
      }),
      selectStep(step) {
        if (step.disabled) {
          return;
        }
        store.commit('setStep', step.key);
      }
    }

  }
}
</script>
