<template>
  <div class="max-w-4xl">
    <form @submit.prevent="saveClaim" class="bg-white rounded-lg p-6 text-gray-800 text-lg">
      <h2 class="text-2xl font-bold text-gray-800 mb-4">
        Admin
      </h2>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="w-full p-1" v-for="field in adminFields" :key="field.key">
          <label class="text-gray-600 font-bold">
            {{ field.label }}
          </label>
          <select
            :disabled="isLimited || isSubmitting"
            :class="{
              'bg-gray-300 text-gray-400': isSubmitting
            }"
            v-if="field.type === 'adjuster'"
            v-model="claim[field.key]">
              <option :value="null" disabled>
                Select an adjuster
              </option>
              <option
                :value="adjuster.id"
                :key="adjuster.id"
                v-for="adjuster in adjusters">
                {{ adjuster.fullName || ((adjuster.firstName || '') + ' ' + (adjuster.lastName || '')) }}
              </option>
          </select>
          <select
            :disabled="isLimited || isSubmitting"
            :class="{
              'bg-gray-300 text-gray-400': isSubmitting
            }"
            v-else-if="field.type === 'status-select'"
            v-model="claim[field.key]">
              <option value="reported">
                Reported
              </option>
              <option value="open">
                Open
              </option>
              <option value="closed">
                Closed
              </option>
              <option value="reopened">
                Reopened
              </option>
              <option value="duplicate-closed">
                Duplicate Closed
              </option>
          </select>      
          <Multiselect 
            :disabled="isLimited || isSubmitting" 
            :class="{
              'bg-gray-300 text-gray-400': isSubmitting
            }" 
            v-else-if="field.type === 'adjuster-code-select'" v-model="assignedAdjusterCodes" :options="adjusterCodes" :custom-label="adjusterCodeCustomLabel" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="label" track-by="code" value="code">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template> 
          </Multiselect>   
          <select
            :disabled="isLimited || isSubmitting"
            :class="{
              'bg-gray-300 text-gray-400': isSubmitting
            }"
            v-else-if="field.type === 'totalloss-select'"
            v-model=claim[field.key]>
              <option :value="null">Unknown</option>
              <option :value="true">
               Is a total loss
              </option>
              <option :value="false">
               Is not a total loss
              </option>              
          </select>          
          <input
          type="text"
          :class="{
            'bg-gray-300 text-gray-400': isSubmitting
          }"
          :disabled="field.disabled || isLimited || isSubmitting"
          v-model="claim[field.key]"
          v-else />
        </div>
      </div>
      <div class="mt-1 text-gray-600" v-if="hasChanged">
        <label class="font-bold">
          Comment
        </label>
        <textarea
          :disabled="isSubmitting"
          :class="{ 'bg-gray-300 text-gray-400': isSubmitting }"
          placeholder="You will need to add a comment to save this change"
          v-model="claim.comment" />
      </div>
      <div class="flex flex-col justify-end mt-2 w-full md:flex-row" v-if="!isLimited">
        <button
          :disabled="!claim.comment || isSubmitting"
          :class="{
            'bg-gray-300 text-gray-400 cursor-default':
              !claim.comment || isSubmitting,
            'bg-lula-gradient text-white hover:bg-lula-gradient-alt':
              claim.comment && !isSubmitting,
          }"
          type="submit"
          class="w-full py-2 my-1 md:my-0 md:mx-1 md:w-min">
            Save
        </button>
      </div>
    </form>
    <div class="bg-white rounded-lg p-6 text-gray-600 text-lg mt-4 select-none" v-if="!isLimited">
      <div class="flex flex-col md:flex-row">
        <div @click= "exportClaim" class="w-full text-center bg-gray-100 p-2 cursor-pointer hover:bg-gray-200">
          Export Claim
        </div>
        <div v-if="canSubmitToTpa"
          @click="sendFnolToTpa"
          class="w-full text-center bg-gray-100 p-2 cursor-pointer hover:bg-gray-200">
            Submit form directly to TPA
        </div>
        <div class="w-full text-center bg-gray-100 p-2" v-else>
          Submit form directly to TPA
        </div>
      </div>
    </div>
    <div class="bg-white rounded-lg p-6 text-gray-800 text-lg mt-4" v-if="!isLimited">
      <div class="flex justify-between items-start pr-2">
        <h2 class="text-2xl font-bold text-gray-800 mb-2.5">
          Audit Log
        </h2>
        <span
          class="font-bold text-gray-600 text-sm cursor-pointer hover:underline"
          :class="{ 'animate-spin': isChangelogLoading }"
          @click="refreshChangelog">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="
                    M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0
                    0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0
                    0013.803-3.7M4.031 9.865a8.25 8.25 0
                    0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </span>
      </div>
      <table class="w-full" v-if="!isChangelogLoading && changelog.length > 0">
        <tr class="border-b text-left text-gray-600">
          <th class="pb-2 font-bold">
            Change
          </th>
          <th class="hidden pb-2 font-light w-52 md:table-cell">
            Created By
          </th>
          <th class="hidden pb-2 font-light w-52 md:table-cell">
            Created
          </th>
        </tr>
        <tr class="text-base text-gray-600" v-for="change in changelog" :key="change.documentId">
          <td class="p-2">
            <div>
              <span>
                {{ change.description }}
              </span>
              <br />
              <span class="hidden text-sm text-gray-500 -mt-1 md:block" v-if="change.note">
                {{ change.note }}
              </span>
              <span class="block text-sm text-gray-500 -mt-1 md:hidden">
                {{ change.timestamp }}
              </span>
            </div>
          </td>
          <td class="hidden p-2 md:table-cell">
            {{ change.actor }}
          </td>
          <td class="hidden p-2 md:table-cell">
            {{ change.timestamp }}
          </td>
        </tr>
        <tr
          class="cursor-pointer hover:bg-gray-100"
          @click="currentChangelogIdx += 5"
          v-if="isMoreChangelogPages">
            <td class="p-2 text-gray-600 font-bold">
              View More
            </td>
            <td />
            <td />
            <td />
        </tr>
      </table>
      <div class="text-gray-600 animate-pulse text-center bg-gray-100 text-sm py-4 rounded" v-else>
        {{ isChangelogLoading ? '&nbsp;' : 'No Changes' }}
      </div>
    </div>    
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import { useLDFlag } from 'launchdarkly-vue-client-sdk'
import Multiselect from 'vue-multiselect'

const adminFields = [
  {
    label: 'Status',
    type: 'status-select',
    key: 'status',
    disabled: false,
  },
  {
    label: 'Adjuster',
    type: 'adjuster',
    key: 'adjuster',
    disabeld: false
  },
  {
    label: 'TPA Claim ID',
    type: 'text',
    key: 'tpaClaimId',
    disabled: false
  },
  {
    label: 'Unique Location Code',
    type: 'text',
    key: 'uniqueLocationCode',
    disabled: true,
  },
  {
    label: 'Adjuster Code',
    type: 'adjuster-code-select',
    key: 'adjusterCode',
    disabled: false,
  },
  {
    label: 'Total Loss',
    type: 'totalloss-select',
    key: 'isTotalLoss',
    disabled: false,
  }
  
];

function formatLabel(label) {
  return label
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
}

function getChangelogDescription(change, vehicles, drivers, adjusters) {

  if(change.eventType === 'third-party-modified')
  {
    if(!change.newValue)
        return `third-party's
                ${formatLabel(change.fieldName)} removed`
    else if(!change.oldValue)
        return `third-party's
                ${formatLabel(change.fieldName)} set to 
                "${change.newValue || 'Null'}"`
    else 
        return  `third-party's
                 ${formatLabel(change.fieldName)} set to
                  "${change.newValue || 'Null'}" from
                 "${change.oldValue || 'Null'}"`
  }
  else if(change.eventType === 'third-party-added')
  return `third-party with
          entityId=${change.newValue} is added`

  switch(change.fieldName) {  
    case 'description': {
      return 'Description modified';
    }
    case 'tpaClaimId': {
      return `TPA Claim ID set to ${change.newValue}`;
    }
    case 'adjuster': {
      const newAdjuster = adjusters.find(a => a.id === change.newValue);
      const adjusterName = newAdjuster?.fullName || `${newAdjuster?.firstName || ''} ${newAdjuster?.lastName || ''}`;
      return !newAdjuster ? 'Adjuster changed' : `${formatLabel(change.fieldName)} set to ${adjusterName}`;
    }
    case 'vehicle': {
      const oldVehicle = vehicles.find(v => v.entityId === change.oldValue);
      const newVehicle = vehicles.find(v => v.entityId === change.newValue);
      return !oldVehicle || !newVehicle ? 'Vehicle changed' : `
        ${formatLabel(change.fieldName)} set to
        ${newVehicle.key || newVehicle.vin} from ${oldVehicle.key || oldVehicle.vin}
      `;
    }
    case 'driver': {
      const oldDriver = drivers.find(d => d.entityId === change.oldValue);
      const newDriver = drivers.find(d => d.entityId === change.newValue);
      return !oldDriver || !newDriver ? 'Driver changed' : `
        ${formatLabel(change.fieldName)} set to
        ${newDriver.firstName} ${newDriver.lastName} from ${oldDriver.firstName} ${oldDriver.lastName}
      `;
    }
    default: {
      if (change.oldValue) {
        return `
          ${formatLabel(change.fieldName)} set to
          ${change.newValue} from ${change.oldValue}
        `;
      }
      return `
        ${formatLabel(change.fieldName)}
        set to ${change.newValue}
      `;
    }
  }
}

export default {
  components:{Multiselect},
  setup() {

    const adjusterCodeFeatureFlagVal = useLDFlag('Claims.AdjusterCode', false);

    const store = useStore();

    const mode = inject('mode') ?? 'cars';
    const isLimited = mode.includes('limited_');
    const tenantId = inject('tenantId') || null;
    const vehicles = inject('vehicles') || [];
    const drivers = inject('drivers') || [];
    const showMessage = inject('showMessage') || (() => null);
    const adjusters = JSON.parse(inject('adjustersJson') || '[]');
    const adjusterCodes = JSON.parse(inject('adjusterCodesJson') || '[]');

    const claim = ref({
      status: store.state.claim.status || 'reported',
      tpaClaimId: store.state.claim.tpaClaimId || null,
      uniqueLocationCode: store.state.claim.uniqueLocationCode || null,
      adjuster: store.state.claim.adjuster || null,
      adjusterCode: store.state.claim.adjusterCode || null,
      comment: '',
      isFnolSubmittedToTpa: store.state.claim.isFnolSubmittedToTpa || false,
      isTotalLoss: store.state.claim.isTotalLoss
    });

    const currentChangelogIdx = ref(5);
    const isChangelogLoading = ref(false);
    const isSubmitting = ref(false);
    const assignedAdjusterCodes = ref(null);
    const initialAssignedAdjusterCodes = ref(null);

    const refreshChangelog = async () => {
      isChangelogLoading.value = true;
      await store.dispatch('getChangelogForClaim', {
        claimId: store.state.claim.id,
        tenantId,
      });
      await new Promise(r => setTimeout(r, 1000));
      isChangelogLoading.value = false;
    }
    const refreshClaim = async (updatedClaim) => {
        claim.value = {
        ...claim.value,
        status: updatedClaim.status,
        isFnolSubmittedToTpa: updatedClaim.isFnolSubmittedToTpa
          }
      };

    onMounted(refreshChangelog);

    onMounted(() => {
      const claimAdjusterCodes = claim.value.adjusterCode;
      if(claimAdjusterCodes)
      {
        assignedAdjusterCodes.value = adjusterCodes.filter(adjCode => claimAdjusterCodes.includes(adjCode.code));
        initialAssignedAdjusterCodes.value = [...assignedAdjusterCodes.value];
      }
    });

    return {
      claim,
      adjusters,
      adjusterCodes,
      assignedAdjusterCodes,
      adminFields: computed(() => {
        return adminFields.filter(field => {
          switch (field.key) {
            case 'adjusterCode': {
              return !isLimited;
            }
            default: {
              return true;
            }
          }
        })
      }),
      isLimited,
      isSubmitting,
      isChangelogLoading,
      refreshChangelog,
      currentChangelogIdx,
      canSubmitToTpa: computed(() =>
        !claim.value.isFnolSubmittedToTpa && claim.value.status === 'reported'
      ),
      adjusterCodeCustomLabel ({ label, code }) {
      return `(${code}) ${label}`
      },
      hasChanged: computed(() => {
        const fieldChanged = adminFields.find(field => {

          if(field.key==="isTotalLoss")
          {
            if(store.state.claim[field.key] !== claim.value[field.key])
             return true;
          }
          if (!store.state.claim[field.key] && !claim.value[field.key]) {
            return false;
          }
          return store.state.claim[field.key] !== claim.value[field.key]
        })            
        if(assignedAdjusterCodes.value)
          return fieldChanged || (JSON.stringify(assignedAdjusterCodes.value) !== JSON.stringify(initialAssignedAdjusterCodes.value));
        else
          return fieldChanged;
      }),
      isMoreChangelogPages: computed(() => {
        if (store.state.changelog) {
          return currentChangelogIdx.value < store.state.changelog.length;
        }
        return false;
      }),
      changelog: computed(() => {
        return (store.state.changelog || [])
          .map(change => {
            const changeDate = moment.utc(change.timestamp).local();
            return {
              documentId: change.documentId,
              actor: change.actor || '',
              description: getChangelogDescription(
                change,
                vehicles,
                drivers,
                adjusters
              ),
              note: change.description || '',
              timestamp: changeDate.format('MM/DD/yyyy h:mmA'),
              changeDate: changeDate.toDate(),
            }
          })
          .sort((a, b) => a.changeDate < b.changeDate ? 1 : -1)
          .filter((_, idx) => idx < currentChangelogIdx.value)
      }),  
      async exportClaim() {
        showMessage('Exporting claim');
        const claimExport = await store.dispatch('getClaimExport', {
          claimId: store.state.claim.id,
          tenantId,
        });
        const download = await axios({ url: claimExport.url, responseType: 'blob' });
        const downloadUrl = URL.createObjectURL(download.data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'claim.zip');
        document.body.appendChild(link);
        link.click();
      },      
      async sendFnolToTpa() {
        showMessage('Sending FNOL to TPA');
        const sendResult = await store.dispatch('sendFnolToTpa', {
          claimId: store.state.claim.id,
          tenantId,
        });
        if(!sendResult || (sendResult && !sendResult.success)){               
            let failureMessage = `Failed to send FNOL to TPA. (${sendResult?.message})`;
            showMessage(failureMessage, true);
            return;
        }
        showMessage('Successfully sent FNOL to TPA');
        var updatedClaim = await store.dispatch('getClaimById',{
          claimId: store.state.claim.id,
          tenantId }
          );              
          refreshClaim(updatedClaim); 
      },     
      async saveClaim() {
        isSubmitting.value = true;
        try {
          if(assignedAdjusterCodes.value)
            claim.value.adjusterCode = assignedAdjusterCodes.value.map(code => code.code).join(",");          
          const save = await store.dispatch('saveClaim', {
            ...store.state.claim,
            ...claim.value,
            comment: claim.value.comment,
            tenantId,
          });
          if (!save) {
            showMessage('Failed to save claim', true);
            return;
          }
          claim.value.comment = '';          
          initialAssignedAdjusterCodes.value = assignedAdjusterCodes.value != null ? [...assignedAdjusterCodes.value] : null;
          showMessage('Claim saved');
        } catch(err) {
          showMessage('Failed to save claim', true);
        } finally {
          isSubmitting.value = false;
        }
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
  .multiselect__tag {   
    background: #ff5701;    
  }
  .multiselect__option--highlight::after {
    background: #ff5701;
  }
  .multiselect__option--highlight {
    background: #ff5701;
  }
</style>

